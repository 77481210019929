<template>
  <div style="font-size: 90%">
   
    <b-modal v-model="show_modal_autopsies" size="lg" hide-footer>
      <Gallery :images="images"></Gallery>
      
      <b-button size="sm" variant="danger" class="w-100" @click="open_pdf" v-if='pdf_autopsies_name'
        ><i class="fas fa-file-pdf"></i><br />
        Open PDF</b-button
      >
      <b-overlay :show="show_modal_data" style="z-index: 1002" no-wrap></b-overlay>
    </b-modal>

    <b-row ref="all_template">
      <!-- <b-col :cols="col_map">-->
      <b-col :lg="col_map" md="12" ref="layer_button">
        <div
          v-if="info_contoler"
          class="position-absolute"
          style="z-index: 9999; top: 0; right: 16.6666666667%"
        >
          <b-card no-body class="text-center shadow">
            <div class="p-1" v-html="html_info"></div>
          </b-card>
        </div>
        <div>
          <!--<div :style="{height: '60vh'}">-->
          <div class="screen-size">
            <div class="h-100" ref="div_map" style="background-color: white">
              <!-- <l-map v-if="false"  ref="map"
      :zoom="zoom"
      :center="center"
      :options="{zoomControl: false}">
      <l-tile-layer :url="url"></l-tile-layer>
      
      <div class="loader"></div>
    </l-map>-->
              <b-overlay :show="!tst" style="z-index: 1002" no-wrap></b-overlay>
              <l-map
                v-if="true"
                ref="map"
                :bounds="bounds"
                :zoom="zoom"
                :center="center"
                @update:zoom="zoomUpdated"
                @update:center="centerUpdated"
                @update:bounds="boundsUpdated"
                :options="{ zoomControl: false }"
              >
                <!--<l-control-zoom position="topright"  ></l-control-zoom>-->
                <!-- @update:bounds="boundsUpdated"-->
                <!--<l-draw-toolbar ref="draw_toolbar" position="topright" :polygon="false"></l-draw-toolbar>-->
                
                
                <!--        Legend-------------------
                  <l-control position="bottomright" :disableScrollPropagation="true"><lagend :object="legend_object"></lagend></l-control>-->


                <l-layer-group ref="features">
                  <!-- <l-popup> <span> {{popup_text}}</span><b-icon icon="arrow-down" aria-hidden="true" scale="1.2"></b-icon></l-popup>-->
                  <l-tooltip>
                    <!--<span> {{popup_text}}</span>-->
                    <i class="fas fa-map-marker-alt"></i>
                  </l-tooltip>
                </l-layer-group>

                <l-control position="topleft" :disableScrollPropagation="true">
                  <div class="" style="z-index: 1005">
                    <b-input-group size="sm">
                      <b-form-input
                        v-model="coordinates_map"
                        placeholder="38.038393, 23.749695"
                      ></b-form-input>
                      <b-button @click="search_coordinates">{{content_all[this.$store.state.languege].add_point}}</b-button>
                    </b-input-group>
                  </div>
                </l-control>

                <l-control class="bottomleft-control" position="bottomleft">
                  <div class="p-2 logo-perifereia">
                    <img
                      src="/img/perifereia_attica_logo.png"
                      style="width: 100%"
                    />
                  </div>
                </l-control>
                <!--<l-control position="bottomright">
        <div v-if="show_legend && select_hazard_with_wms_flood">
        <Legend :values="legend_values" :layers_name='layers_flood' :active_layers="boolean_layers_flood" :object='object_legend'></Legend>
        </div>
      </l-control>-->
                <l-control
                  v-if="tree_reload"
                  :disableScrollPropagation="true"
                  class="d-flex flex-column"
                  position="topright"
                >
                  <div
                    class="control-desk"
                    style="min-width: 330px; max-width: 330px"
                  >
                    <b-card no-body class="" style="background: #003b5d">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion
                          class="d-flex justify-content-between collapse-button"
                          style="font-size: 14px"
                          ><div>
                            <b-icon
                              icon="layers-fill"
                              aria-hidden="true"
                              scale="1.2"
                            ></b-icon>
                          
                          {{content_all[this.$store.state.languege].general_layers}}
                          </div>
                          <b-icon icon="arrow-bar-down" scale="1.5"></b-icon>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <div class="p-1">
                            <div
                              class="mt-1 rounded style-2"
                              style="
                                overflow-y: scroll;
                                overflow-x: hidden;
                                max-height: 50vh;
                                background: #c7c7c7;
                              "
                            >
                              <v-jstree
                                :data="data"
                                show-checkbox
                                multiple
                                allow-batch
                                whole-row
                                @item-click="itemClick"
                                :draggable="false"
                              >
                              </v-jstree>
                            </div>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <b-card no-body class="" style="background: #003b5d">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          block
                          v-b-toggle.accordion-2
                          class="d-flex justify-content-between collapse-button"
                          style="font-size: 14px"
                          ><div>
                            <b-icon
                              icon="layers-fill"
                              aria-hidden="true"
                              scale="1.2"
                            ></b-icon>
                            Δασικές Πυρκαγιές
                          </div>
                          <b-icon icon="arrow-bar-down" scale="1.5"></b-icon>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        id="accordion-2"
                        accordion="my-accordion"
                        role="tabpanel"
                        class="mt-1"
                      >
                        <b-card-body>
                          <div
                            v-for="(areas, index) in areas_array"
                            :key="index"
                            class="py-1"
                          >
                            <b-card
                              no-body
                              class=""
                              style="background: #003b5d"
                            >
                              <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                              >
                                <b-button
                                  block
                                  v-b-toggle="['accordion-' + index + 2]"
                                  class="
                                    d-flex
                                    justify-content-between
                                    collapse-button
                                  "
                                  style="font-size: 14px"
                                  ><div>
                                    <b-icon
                                      icon="layers-fill"
                                      aria-hidden="true"
                                      scale="1.2"
                                    ></b-icon>
                                    {{ areas.name }}
                                  </div>
                                  <b-icon
                                    icon="arrow-bar-down"
                                    scale="1.5"
                                  ></b-icon
                                ></b-button>
                              </b-card-header>
                              <b-collapse
                                :id="'accordion-' + index + 2"
                                accordion="my-accordionn"
                                role="tabpanel"
                              >
                                <b-card-body>
                                  <div class="p-1">
                                    <div
                                      class="mt-1 rounded style-2"
                                      style="
                                        overflow-y: scroll;
                                        overflow-x: scroll;
                                        max-height: 50vh;
                                        background: #c7c7c7;
                                      "
                                    >
                                      <v-jstree
                                        :data="areas.data"
                                        show-checkbox
                                        multiple
                                        allow-batch
                                        whole-row
                                        @item-click="itemClick_flood"
                                      >
                                      </v-jstree>
                                    </div>
                                  </div>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                    <!-- <div v-for="(areas,index) in areas_array" :key="index">
              <b-card no-body class="" style="background:#003b5d;">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="['accordion-'+index+2]" class="d-flex justify-content-between collapse-button"
                style="font-size:14px"><div><b-icon icon="layers-fill" aria-hidden="true" scale="1.2"></b-icon> {{areas.name}}:</div><b-icon icon="arrow-bar-down" scale="1.5" ></b-icon></b-button>
              </b-card-header>
              <b-collapse :id="'accordion-'+index+2" accordion="my-accordion" role="tabpanel">
                <b-card-body >
                  <div class="p-1">
                        <div class="mt-1 rounded style-2" style="overflow-y: scroll; overflow-x: scroll; max-height: 50vh; background:#c7c7c7">
                        
                          <v-jstree :data="areas.data" show-checkbox multiple allow-batch whole-row @item-click="itemClick_flood">
                            
                          </v-jstree>
                          
                        </div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
            </div-->
                  </div>
                </l-control>

                <l-control-scale
                  position="topleft"
                  :imperial="true"
                  :metric="true"
                ></l-control-scale>
                <l-control-layers position="topleft"></l-control-layers>
                <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.name"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  :attribution="tileProvider.attribution"
                  layer-type="base"
                />
                <l-control-zoom position="topleft"></l-control-zoom>
                <l-control
                  position="topleft"
                  class="leaflet-control-layers"
                  style="padding: 0.55rem !important"
                >
                  <b-icon
                    v-if="!show_my_location"
                    @click="myLocation"
                    icon="cursor-fill"
                    aria-hidden="true"
                    scale="1.5"
                  ></b-icon>
                  <b-icon
                    v-if="show_my_location"
                    @click="myLocation"
                    icon="cursor-fill"
                    aria-hidden="true"
                    scale="1.5"
                    variant="success"
                  ></b-icon>
                </l-control>
                <l-control-polyline-measure
                  :options="{
                    showUnitControl: true,
                    showClearControl: true,
                    clearMeasurementsOnStop: false,
                  }"
                  position="topleft"
                />

                <!--Add Point-->
                <l-marker
                  v-if="coordinates_map_center"
                  :lat-lng="coordinates_map_center"
                ></l-marker>
                <!-- Add Point END-->

                <!--GPS-->
                <l-marker
                  v-if="show_my_location && coordinates_map_center_location"
                  :lat-lng="coordinates_map_center_location"
                >
                  <l-icon>
                    <b-icon
                      icon="circle-fill"
                      animation="throb"
                      font-scale="2"
                      variant="primary"
                    ></b-icon>
                  </l-icon>
                </l-marker>
                <l-circle
                  v-if="show_my_location && coordinates_map_center_location"
                  :lat-lng="coordinates_map_center_location"
                  :radius="radius_coordinates_map_center_location"
                />
                <!--GPS END-->

                <!--General Layers-->
                <div v-for="(all_layers, ind) in layersData" :key="ind">
                  <div
                    v-if="booleanOflayerArray[nameOflayerArray.indexOf(ind)]"
                  >
                    <div v-for="(polygon, index) in all_layers" :key="index">
                      <l-geo-json
                        v-if="polygon.center"
                        :geojson="{
                          type: 'FeatureCollection',
                          features: [
                            {
                              type: 'Feature',
                              geometry: JSON.parse(polygon.center),
                              properties: {
                                name: polygon.name,
                                type: ind,
                                center: polygon.center,
                                num: index,
                              },
                            },
                          ],
                        }"
                        :optionsStyle="
                          colorsArray[nameOflayerArray.indexOf(ind)]
                        "
                        :options="optionsGeoJson"
                      >
                      </l-geo-json>
                      <div v-if="polygon[[ind + '_geom']]">
                        <l-geo-json
                          v-if="
                            JSON.parse(polygon[[ind + '_geom']]).type !==
                            'MultiPoint'
                          "
                          :geojson="{
                            type: 'FeatureCollection',
                            features: [
                              {
                                type: 'Feature',
                                geometry: JSON.parse(polygon[ind + '_geom']),
                                properties: { name: polygon.name, type: ind },
                              },
                            ],
                          }"
                          :optionsStyle="
                            colorsArray[nameOflayerArray.indexOf(ind)]
                          "
                          :options="optionsGeoJson"
                        >
                        </l-geo-json>
                      </div>
                    </div>
                  </div>
                </div>
                <!--General Layers END-->

                <!--Area of interest polygons-->
                <div v-if="selected_hazard">
                  <div
                    v-for="(polygon, index) in area_of_interest_data"
                    :key="index"
                  >
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.hazard_geom),
                            properties: { name: polygon.hazname },
                          },
                        ],
                      }"
                      
                      :options="optionsGeoJsonHazard"
                    ></l-geo-json>
                  </div>
                </div>
                <!--Area of interest polygons END-->

                <!--Drill Points-->
                <div v-for="(all, location,inx) in drill_data " :key="inx+'drill_data'">
                <div v-if="show_seismic_drill[location] && selected_area_of_interest.includes(location)">
                  
                  <div v-for="(polygon, index) in all" :key="index">
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.center),
                            properties: {
                              name: polygon.name,
                              type: 'drill',
                              center: polygon.center,
                              num: index,
                              location:location,
                            },
                          },
                        ],
                      }"
                      :optionsStyle="{
                        color: '#da4c3b',
                        fillOpacity: fillOpacity,
                      }"
                      :options="optionsGeoJsonSeismic"
                    ></l-geo-json>
                  </div>
                </div>
                </div>
                <!--Drill Points END-->


                <!--Xoroi Katafigis Points-->
                <div v-for="(all, location,inx) in xoroi_katafigis " :key="inx+'xoroi_katafigis'">
                <div v-if="show_seismic_xoroi_katafigis[location] && selected_area_of_interest.includes(location)">
                  <!--show_seismic_drill-->
                  <div v-for="(polygon, index) in all" :key="index">
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.center),
                            properties: {
                              name: polygon.name,
                              type: 'drill',
                              center: polygon.center,
                              num: index,
                              location:location,
                            },
                          },
                        ],
                      }"
                      :optionsStyle="{
                        color: '#da4c3b',
                        fillOpacity: fillOpacity,
                      }"
                      
                    ></l-geo-json>
                  </div>
                </div>
                </div>
                <!--Xoroi Katafigis Points END-->

                <!--Seismic Points -->
                 <div v-for="(all, location,inx) in photos_data " :key="inx+'photos_data'">
                <div v-if="show_seismic_photos[location] && selected_area_of_interest.includes(location)">
                  <div v-for="(polygon, index) in all" :key="index">
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.center),
                            properties: {
                              name: polygon.name,
                              type: 'photos',
                              center: polygon.center,
                              num: index,
                              date: polygon.datetime,
                              location:location
                            },
                          },
                        ],
                      }"
                      :optionsStyle="{
                        color: '#da4c3b',
                        fillOpacity: fillOpacity,
                      }"
                      :options="optionsGeoJsonSeismic"
                    ></l-geo-json>
                  </div>
                </div>
                 </div>
                <!--Seismic Points END-->

                <!--Dasiki pirkagia krisima simia-->
                <div v-for="(polygon, index, i) in autopsies_data" :key="i">
                  <div v-if="location_array.includes(polygon.locality)">
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.center),
                            properties: {
                              name: polygon.name,
                              type: 'autopsies',
                              color: polygon.hazardcat,
                              center: polygon.center,
                              num: index,
                              folderpath: polygon.folderpath,
                              location: polygon.locality,
                            },
                          },
                        ],
                      }"
                      :optionsStyle="{
                        color: '#da4c3b',
                        fillOpacity: fillOpacity,
                      }"
                      :options="optionsGeoJsonAutopsies"
                    ></l-geo-json>
                  </div>
                </div>

                <div v-for="(polygon, index, i) in wfsp_data" :key="i">
                  <div v-if="location_array.includes(polygon.locality)">
                    <l-geo-json
                      :geojson="{
                        type: 'FeatureCollection',
                        features: [
                          {
                            type: 'Feature',
                            geometry: JSON.parse(polygon.center),
                            properties: {
                              name: polygon.name,
                              type: 'wfsp',
                              color: polygon.hazardcat,
                              center: polygon.center,
                              num: index,
                              folderpath: polygon.folderpath,
                              location: polygon.locality,
                            },
                          },
                        ],
                      }"
                      :optionsStyle="{
                        color: '#da4c3b',
                        fillOpacity: fillOpacity,
                      }"
                      :options="optionsGeoJsonAutopsies"
                    ></l-geo-json>
                  </div>
                </div>
                <!--Dasiki pirkagia krisima simia END-->

                 <!--dimoi attikis-->
                <!--<l-wms-tile-layer
                    :base-url="wmsLayer.url"
                    :layers="'perifereia:geodata_dimoi_attikis_kallikratis'"
                    :transparent="true"
                    format="image/png"
                    :zIndex="7"
                    :opacity="1"
                  >
                  </l-wms-tile-layer>-->
                <!--dimoi attikis end-->
                <div v-for="(bool, value) in general_geoserver_bool" :key="value+'layers_flood'">
                  <div v-if="bool">
                  <l-wms-tile-layer
                    :base-url="wmsLayer.url"
                    :layers="value"
                    :transparent="true"
                    format="image/png"
                    :zIndex="7"
                    :opacity="1"
                  >
                  </l-wms-tile-layer>
                  </div>
                </div>

                <!--Flood Layers-->
                <div v-if="select_hazard_with_wms_flood && reload_layers">
                  <div v-for="(all, location,ind) in layers_flood" :key="ind+'layers_flood'">
                    <div v-if="selected_area_of_interest.includes(location)">
                    <div v-for="(layer, index) in all" :key="index">
                    <l-wms-tile-layer
                      ref="wms_layer"
                      :key="wmsLayer.name"
                      :base-url="
                        wmsLayer.url + '?viewparams=' + layer.split('-!')[1]
                      "
                      :layers="layer.split('-!')[0]"
                      :visible="
                        boolean_layers_flood[location][index] &&
                        select_hazard_with_wms_flood
                      "
                      :transparent="true"
                      format="image/png"
                      :zIndex="12"
                      :opacity="layer.includes('opacity:')===true ? parseFloat(layer.split('opacity:')[1]) :0.9 "
                    >
                    </l-wms-tile-layer>
                    </div>
                    </div>
                  </div>
                  <!-- Flood kliseis-pyros
                 
                  <div v-for="(all1,location) in flood_kliseis_pyros" :key="location+'mm'">
                    <div v-if="selected_area_of_interest.includes(location)">
                    
                  <div v-for="(all, mm) in all1" :key="mm+'flood_kliseis_pyros'">
                    <div v-if="boolean_flood_kliseis_pyros[location][mm]">
                       
                       <div v-for="(points, ind) in all" :key="ind + 'flood_kliseis_pyross'"> 

                        <l-circle-marker
                          :lat-lng="[points.y,points.x]"
                          :radius="6"
                          :color="colors_kliseis_flood[points.dateright]"
                          :fillColor="colors_kliseis_flood[points.dateright]"
                          :fillOpacity="1"
                        />
                       </div>
                      
                  </div>
                    </div>
                 
                  </div>
                  </div>-->
                  <!-- Flood kliseis-pyros end-->
                   <!-- Flood Shelters-->
                 <!-- <div
                    v-for="(flood_points, ind) in flood_shelters"
                    :key="ind + 'points'"
                  >
                    <div v-if="boolean_flood_shelters[ind]">
                      <div v-for="(points, index) in flood_points" :key="index">-->
                        <div v-for="(all1,mm) in flood_shelters" :key="mm+'mm'">
                     <div v-if="boolean_flood_shelters[mm]">
                  <div v-for="(all, location) in all1" :key="location+'flood_shelters'">
                  <div v-if="selected_area_of_interest.includes(location)">
                       <div v-for="(points, ind) in all" :key="ind + 'flood_shelterss'"> 
                        <l-geo-json
                          :geojson="{
                            type: 'FeatureCollection',
                            features: [
                              {
                                type: 'Feature',
                                geometry: JSON.parse(points.center),
                                properties: {
                                  name:points.name_1,
                                  location: 'flood',
                                },
                              },
                            ],
                          }"
                          :options="optionsGeoJsonFloodShelters"
                        >
                        </l-geo-json>
                       </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <!-- Flood Shelters end-->
                  <!--Flood Critical Points-->
                   <!--<div v-for="(all, location) in flood_critical_points" :key="location+'layers_flood'">
                    <div v-if="selected_area_of_interest.includes(location)">
                  <div
                    v-for="(flood_points, ind) in all"
                    :key="ind + 'critical points'"
                  >
                    <div v-if="boolean_flood_critical_points[ind]">
                      <div v-for="(points, index) in flood_points" :key="index">
  
                        <l-geo-json
                          :geojson="{
                            type: 'FeatureCollection',
                            features: [
                              {
                                type: 'Feature',
                                geometry: JSON.parse(points.center),
                                properties: {
                                  name:
                                    points.info +
                                    '-!' +
                                    flood_critical_points_names[location][ind].split(
                                      '-!'
                                    )[1],
                                  folderpath: points.name,
                                  location: 'flood',
                                },
                              },
                            ],
                          }"
                          :options="optionsGeoJsonFlood"
                        >
                        </l-geo-json>
                      </div>
                    </div>
                  </div>
                    </div>
                   </div>-->
                   <div v-for="(all, priority) in flood_critical_points" :key="priority+'layers_flood'">
                    
                    <div
                      v-for="(flood_points, type_of_point) in all"
                      :key="type_of_point + 'critical points'"
                    >
                      <div v-if="boolean_flood_critical_points[flood_critical_points_names.indexOf(type_of_point+'-!'+priority+'-!critical points')]"><!--boolean_flood_critical_points[ind]  flood_critical_points_names[location][inm].split(
                                        '-!'
                                      )[1]-->
                        <div v-for="(points, location) in flood_points" :key="location">
                        <div v-if="selected_area_of_interest.includes(location)">
                          <div v-for="(point, inm) in points" :key="inm+'cr_point'">
                          <l-geo-json
                            :geojson="{
                              type: 'FeatureCollection',
                              features: [
                                {
                                  type: 'Feature',
                                  geometry: JSON.parse(point.center),
                                  properties: {
                                    name:
                                      point.info +'-!'+priority+
                                      '-!',
                                    folderpath: point.name,
                                    location: 'flood',
                                    place:location
                                  },
                                },
                              ],
                            }"
                            :options="optionsGeoJsonFlood"
                          >
                          </l-geo-json>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                   </div>
                  <!--Flood Critical Points END-->

                  <!--Flood Hydrograpgic-->
                  <div v-for="(all1,mm) in flood_hydropraphic_data" :key="mm+'all1'">
                     <div v-if="boolean_flood_hydropraphic_data[mm]">
                  <div v-for="(all, location) in all1" :key="location+'flood_hydropraphic_data'">
                  <div v-if="selected_area_of_interest.includes(location)">
                  <div
                    v-for="(points, ind) in all"
                    :key="ind + 'hydropraphic'"
                  >
                   
                     
                        <l-geo-json
                          :geojson="{
                            type: 'FeatureCollection',
                            features: [
                              {
                                type: 'Feature',
                                geometry: JSON.parse(points.center),
                                properties: {
                                  label: points.label,
                                  name: points.name,
                                  diastaseis: points.diastaseis,
                                  koiti: points.koiti,
                                  color: color_flood_hydropraphic[points.koiti],
                                },
                              },
                            ],
                          }"
                          :optionsStyle="{
                            color: color_flood_hydropraphic[points.koiti],
                            weight: 7,
                          }"
                          :options="optionsGeoJsonFloodHydropraphic"
                        >
                        </l-geo-json>
                      
                   
                  </div>
                  </div>
                  </div>
                    </div>
                  </div>
                  <!--Flood Hydrograpgic END-->
                </div>
                <!--Flood Layers END-->

                <!--Fire Layers-->

                <div v-if="select_hazard_with_wms_fire && reload_layers">
                  <div v-for="(all, location,ind) in layers_fire" :key="ind+'layers_seismic'">
                    <div v-if="selected_area_of_interest.includes(location)">
                      <div v-for="(layer, index) in all" :key="index">
                        <l-wms-tile-layer
                          ref="wms_layer"
                          :key="wmsLayer.name"
                          :base-url="
                            wmsLayer.url + '?viewparams=' + layer.split('-!')[1]
                          "
                          :layers="layer.split('-!')[0]"
                          :visible="
                            boolean_layers_fire[location][index] &&
                            select_hazard_with_wms_fire
                          "
                          :transparent="true"
                          format="image/png"
                          :zIndex="12"
                          :opacity="layer.includes('opacity:')===true ? parseFloat(layer.split('opacity:')[1]) :0.9 "
                        >
                        </l-wms-tile-layer>
                      </div>
                    </div>
                  </div>
                  <div v-if="show_ignition_points">
                  <div v-for="(index, te) in ignition_points" :key="te + 'te'">
                    <l-marker v-if="selected_area_of_interest.includes(index[2])" :lat-lng="[index[0],index[1]]">
                      <l-tooltip>{{ index[3] }} <br> {{index[4]}}</l-tooltip>
                    </l-marker>
                  </div>
                  </div>
                  <div v-for="(index, te) in ignition_point" :key="te + 'te'">
                    <l-marker v-if="index > 0 && selected_area_of_interest.includes(ignition_points[te][2])" :lat-lng="[ignition_points[te][0],ignition_points[te][1]]">
                      <l-tooltip>{{ ignition_points[te][3] }} <br> {{ignition_points[te][4]}}</l-tooltip>
                    </l-marker>
                  </div>
                </div>
                <div v-for="(all1,trr) in array_photos_lat_lng" :key="trr + 'all1'">
                  
                <div v-for="(all, location,tr) in all1" :key="tr + 'all'">
                  <div v-if="all1[location].length">
                  <div v-if="bool_autopsies_fire_array[all1[location][0].name]">
                  <div v-if="selected_area_of_interest.includes(location)">
                <div  v-for="(index, ter) in all"
                    :key="ter + 'array_photos'">
                    
                    <l-circle-marker
                      :radius="8"
                      :lat-lng="index.latlng"
                      @click="Point_img_Click(index.folder)"
                      :color="colors_autopsies_fire[index.name]"
                      :fillColor="colors_autopsies_fire[index.name]"
                    >
                      <l-tooltip> {{index.text}}</l-tooltip>
                      <l-popup class="d-flex"><i class="fas fa-map-marker"></i> {{index.text}}</l-popup>
                    </l-circle-marker>
                  
                 
                </div>
                  </div>
                </div>
                </div>
                  </div>
                </div>
                <!--Fire Layers END-->
                <!--Dasikes pirkagies Layers-->
                <div v-for="(layer, index) in layers_autopsies" :key="index">
                  <l-wms-tile-layer
                    ref="wms_layer"
                    :key="wmsLayer.name"
                    :base-url="
                      wmsLayer.url + '?viewparams=' + layer.split('-!')[1]
                    "
                    :layers="layer.split('-!')[0]"
                    :visible="boolean_layers_autopsies[index]"
                    :transparent="true"
                    format="image/png"
                    :zIndex="12"
                    :opacity="1"
                  >
                  </l-wms-tile-layer>
                </div>
                <!--Dasikes pirkagies Layers END-->

                <!--Seismic Layers-->
                <div v-if="select_hazard_with_wms_seismic && reload_layers">
                  <div v-for="(all, location,ind) in layers_seismic" :key="ind+'layers_seismic'">
                    <div v-if="selected_area_of_interest.includes(location)">
                      <div v-for="(layer, index) in all" :key="index">
                      <!--url_test && select_hazard_with_wms_flood-->

                      <l-wms-tile-layer
                        ref="wms_layer"
                        :key="wmsLayer.name"
                        :base-url="
                          wmsLayer.url + '?viewparams=' + layer.split('-!')[1]
                        "
                        :layers="layer.split('-!')[0]"
                        :visible="boolean_layers_seismic[location][index]"
                        :transparent="true"
                        format="image/png"
                        :zIndex="12"
                        :opacity="layer.includes('opacity:')===true ? parseFloat(layer.split('opacity:')[1]) :0.9 "
                      >
                      </l-wms-tile-layer>
                      </div>
                    </div>
                  </div>
                </div>
                <!--Seismic Layers END-->
              </l-map>
            </div>
          </div>
        </div>

    
      </b-col>

      <b-col
        :lg="col_sidebar_right"
       
        class="z-100 rounded right-sidebar d-flex align-items-end flex-column"
        style="height:95vh; overflow-y: scroll; overflow-x: hidden;"
      >
        <b-row class="w-100">
          <b-col>
            <div class="p-1">
              <div class="shadow rounded card-layers">
                <div
                  class="
                    w-100
                    
                    d-flex
                    justify-content-center
                    text-white
                    align-items-center
                    rounded-top
                  "
                  style="background: #003b5d"
                >
                {{content_all[this.$store.state.languege].select_risk}}:
                </div>

                <div class="p-1">
                  <b-form-select
                    v-model="selected_hazard"
                    class="ml-auto"
                    size="sm"
                    @change="ChangeSelectedHazard"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- {{content_all[$store.state.languege].select_risk}} --</b-form-select-option
                      >
                    </template>
                    <b-form-select-option
                      v-for="(risk_name, index, i) in risk_data"
                      :key="i"
                      :value="risk_name.haztype"
                      >{{ risk_name.haztype }}</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="p-1">
              <div
                class="shadow rounded"
                :style="{ background: color_selectedArea }"
              >
                <div
                  class="
                    w-100
                    
                    d-flex
                    justify-content-center
                    text-white
                    align-items-center
                    rounded-top
                  "
                  :style="{ background: color_selectedArea }"
                >
                {{content_all[this.$store.state.languege].select_aoi}}:
                </div>
                <div class="p-1">
                  <!--<b-form-select
                    :disabled="selected_hazard === null"
                    v-model="selected_area_of_interest"
                    class="ml-auto"
                    @change="ChangeSelectedAreaOfInterest"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >-- Select Area Of Interest--</b-form-select-option
                      >
                    </template>

                    <b-form-select-option
                      v-for="(
                        area_of_interest_name, index
                      ) in area_of_interest_data"
                      :key="index"
                      :value="area_of_interest_name.hazname + '_' + index"
                      >{{ area_of_interest_name.hazname }}
                    </b-form-select-option>
                  </b-form-select>-->
                  <multi-select v-if="area_of_interest_data != null" v-model="selected_area_of_interest" :internal-search="false" placeholder="Add a Place" :options="locations" :multiple="true" :searchable="false" :show-no-results="false" :hide-selected="true" @input="ChangeSelectedAreaOfInterest">
                  </multi-select>
                  <!--<multi-select v-model="valuemultiselect" :options="optionsmultiselect" :disabled="selected_hazard === null"></multi-select>  -->  
                </div>
              </div>
            </div>
          </b-col>
          
        </b-row>
        <div class="w-100">
        <div class="p-1 control-desk" v-if="select_hazard_with_wms_seismic && selected_area_of_interest.length">
          <div class="card-layers rounded shadow">
            <div
              class="
                w-100
                d-flex
                p-1
                justify-content-center
                text-white
                align-items-center
                rounded-top
              "
              style="background: #003b5d"
            >
              <b-button v-b-toggle.collapse-1 block variant="outline-primary" size="sm" style="padding: 0.175rem 1rem!important;">Select Layer:</b-button>
            </div>
            <b-collapse visible  id="collapse-1" class="">
            <div class="p-1">
              <div
                class="mt-1 rounded style-2"
                style="
                  overflow-y: scroll;
                  overflow-x: scroll;
                  max-height: 50vh;
                  background: #c7c7c7;
                "
              >
                <v-jstree
                  :data="data_tree_seismic"
                  show-checkbox
                  multiple
                  allow-batch
                  whole-row
                  @item-click="itemClick_flood"
                >
                <template slot-scope="_">
                    <div style="display: inherit;">
                      
              
                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                    
                      <span class="px-1" v-html=" _.model.text + ' '"></span>
                      <span v-if="_.model.show_opacity">
                      
                        <i :id="_.model.id" data-bs-toggle="dropdown" class="fa-solid fa-circle-info px-1" @click.stop=""></i>
                           
                            <ul class="dropdown-menu">
                              <li class="row text-center">  
                                <label for='customRange1' class='col-12' style="font-size:12px">Opacity</label>
                                <input :value='_.model.children.length > 0 ? (parseFloat(_.model.children[0].value[0].layer.split("opacity:")[1])*100) : parseFloat(_.model.value[0].layer.split("opacity:")[1])*100 ' type='range' class='form-range col-12' id='customRange1' @click.stop="" @change.stop='changeRange($event,_.model,selected_hazard)'>
                              </li>
                             
                            </ul>
                      

                      </span>
                    </div>
                </template>
                </v-jstree>
              </div>
              <!--<div class="p-1 rounded" style="background: #c7c7c7">
                <div class="text-center font-weight-bold">Layer Opacity:</div>
                <vue-slider
                  v-model="valueOpacity_obj.seismic"
                  class="p-2"
                ></vue-slider>
              </div>-->
            </div>
            </b-collapse>
          </div>
        </div>

        <div
          class="p-1 control-desk"
          v-if="(select_hazard_with_wms_flood || select_hazard_with_wms_fire)"
        >
          <div class="card-layers rounded shadow">
            <div
              class="
                w-100
                d-flex
                p-1
                justify-content-center
                text-white
                align-items-center
                rounded-top
              "
              style="background: #003b5d"
            >
              
              <b-button v-b-toggle.collapse-2 block variant="outline-primary" size="sm" style="padding: 0.175rem 1rem!important;">Select Layer:</b-button>
            </div>
             <b-collapse visible  id="collapse-2" class="">
            <div class="p-1" v-if="itemClick_flood">
              <div
                class="mt-1 rounded style-2"
                style="
                  overflow-y: scroll;
                  overflow-x: scroll;
                  max-height: 50vh;
                  background: #c7c7c7;
                "
              >
                <v-jstree
                  :data="data_tree_risk"
                  show-checkbox
                  :multiple="true"
                  allow-batch
                  whole-row
                  @item-click="itemClick_flood"
                  :draggable="false"
                >
                <template slot-scope="_">
                    <div style="display: inherit;">
                      
              
                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                    
                      <span class="px-1" v-html=" _.model.text + ' '"></span>
                      <span v-if="_.model.show_opacity">
                      
                        <i :id="_.model.id" data-bs-toggle="dropdown" class="fa-solid fa-circle-info px-1" @click.stop=""></i>
                           
                            <ul class="dropdown-menu">
                              <li class="row text-center">  
                                <label for='customRange1' class='col-12' style="font-size:12px">Opacity</label>
                                <input :value='_.model.children.length > 0 ? (parseFloat(_.model.children[0].value[0].layer.split("opacity:")[1])*100) : parseFloat(_.model.value[0].layer.split("opacity:")[1])*100 ' type='range' class='form-range col-12' id='customRange1' @click.stop="" @change.stop='changeRange($event,_.model,selected_hazard)'>
                              </li>
                             
                            </ul>
                      

                      </span>
                    </div>
                </template>
                </v-jstree>
              </div>
              <div class="mt-1 rounded" style="background: #c7c7c7">
                <div
                  class="d-flex justify-content-center"
                  style="font-size: 13px"
                >
                  <!--<b-container class="bv-example-row">
                    <div class="text-center font-weight-bold">
                      Layer Opacity:
                    </div>
                       
                    <b-row class="p-2">
                      <b-col cols="12" class="d-flex align-items-center">
                        <div>Vulnerability:</div>
                        <vue-slider
                          v-model="valueOpacity_obj.vulnerability"
                          :tooltip-placement="['bottom']"
                          class="w-75 px-2"
                        ></vue-slider
                      ></b-col>

                      <b-col cols="12" class="d-flex align-items-center">
                        <div>Exposure:</div>
                        <vue-slider
                          v-model="valueOpacity_obj.exposure"
                          :tooltip-placement="['bottom']"
                          class="w-75 px-2"
                        ></vue-slider
                      ></b-col>

                      <b-col cols="12" class="d-flex align-items-center">
                        <div>Hazard:</div>
                        <vue-slider
                          v-model="valueOpacity_obj.hazard"
                          :tooltip-placement="['bottom']"
                          class="w-75 px-2"
                        ></vue-slider
                      ></b-col>

                      <b-col cols="12" class="d-flex align-items-center">
                        <div>Risk:</div>
                        <vue-slider
                          v-model="valueOpacity_obj.risk"
                          :tooltip-placement="['bottom']"
                          class="w-75 px-2"
                        ></vue-slider>
                      </b-col>
                    </b-row>
                  </b-container>-->
                </div>
              </div>
            </div>
             </b-collapse>
          </div>
        </div>

        <div class="accordion px-1 collapse-mobile shadow" role="tablist">
          <b-card no-body class="" style="background: #003b5d">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-1
                size="sm"
                class="btn_collapse collapse-button"
              >
                Select Layer:</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="collapse_card">
                <div v-if="select_hazard_with_wms_seismic">
                  <div class="card-layers rounded-bottom shadow p-1">
                    <div
                      class="mt-1 rounded style-2"
                      style="
                        overflow-y: scroll;
                        overflow-x: scroll;
                        max-height: 30vh;
                        background: #c7c7c7;
                      "
                    >
                      <v-jstree
                        :data="data_tree_seismic"
                        show-checkbox
                        multiple
                        allow-batch
                        whole-row
                        @item-click="itemClick_flood"
                      >
                      
                      </v-jstree>
                    </div>
                    <!--<div class="p-1 rounded" style="background: #c7c7c7">
                      
                      <div class="text-center font-weight-bold">
                        Layer Opacity:
                      </div>
                      <vue-slider
                        v-model="valueOpacity_obj.seismic"
                        class="p-2"
                      ></vue-slider>
                    </div>-->
                  </div>
                </div>

                <div
                  v-if="
                    select_hazard_with_wms_flood || select_hazard_with_wms_fire
                  "
                >
                  <div class="card-layers rounded-bottom shadow p-1">
                    <div
                      class="p-1 rounded style-2"
                      style="
                        overflow-y: scroll;
                        overflow-x: scroll;
                        max-height: 50vh;
                        background: #c7c7c7;
                      "
                    >
                      <v-jstree
                        :data="data_tree_risk"
                        show-checkbox
                        multiple
                        allow-batch
                        whole-row
                        @item-click="itemClick_flood"
                      >
                      </v-jstree>
                    </div>
                    <div class="mt-1 rounded" style="background: #c7c7c7">
                      <!--<b-container class="bv-example-row">
                        <div class="text-center font-weight-bold">
                          Layer Opacity:
                        </div>
                        <b-row class="p-2">
                          <b-col cols="12" class="d-flex align-items-center">
                            <div>Vulnerability:</div>
                            <vue-slider
                              v-model="valueOpacity_obj.vulnerability"
                              :tooltip-placement="['bottom']"
                              class="w-75 px-2"
                            ></vue-slider
                          ></b-col>

                          <b-col cols="12" class="d-flex align-items-center">
                            <div>Exposure:</div>
                            <vue-slider
                              v-model="valueOpacity_obj.exposure"
                              :tooltip-placement="['bottom']"
                              class="w-75 px-2"
                            ></vue-slider
                          ></b-col>

                          <b-col cols="12" class="d-flex align-items-center">
                            <div>Hazard:</div>
                            <vue-slider
                              v-model="valueOpacity_obj.hazard"
                              :tooltip-placement="['bottom']"
                              class="w-75 px-2"
                            ></vue-slider
                          ></b-col>

                          <b-col cols="12" class="d-flex align-items-center">
                            <div>Risk:</div>
                            <vue-slider
                              v-model="valueOpacity_obj.risk"
                              :tooltip-placement="['bottom']"
                              class="w-75 px-2"
                            ></vue-slider>
                          </b-col>
                        </b-row>
                      </b-container>-->
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="" style="background: #003b5d">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-2
                size="sm"
                class="btn_collapse collapse-button"
                ><b-icon
                  icon="layers-fill"
                  aria-hidden="true"
                  scale="1.2"
                ></b-icon>
                General Layers</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-2"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="collapse_card">
                <div class="card-layers rounded-bottom shadow">
                  <div class="p-1">
                    <div
                      class="rounded style-2"
                      style="
                        overflow-y: scroll;
                        overflow-x: hidden;
                        max-height: 50vh;
                        background: #c7c7c7;
                      "
                    >
                      <v-jstree
                        :data="data"
                        show-checkbox
                        multiple
                        allow-batch
                        whole-row
                        @item-click="itemClick"
                        :draggable="false"
                      >
                      </v-jstree>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="" style="background: #003b5d">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                v-b-toggle.accordion-20
                class="btn_collapse collapse-button"
                size="sm"
                ><div>
                  <b-icon
                    icon="layers-fill"
                    aria-hidden="true"
                    scale="1.2"
                  ></b-icon>
                  Περιοχές Επισκόπησης:
                </div>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-20"
              accordion="my-accordionn"
              role="tabpanel"
              class="mt-1"
            >
              <b-card-body>
                <div v-for="(areas, index) in areas_array" :key="index">
                  <b-card no-body class="" style="background: #003b5d">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <b-button
                        block
                        v-b-toggle="['accordion-' + index + 3]"
                        class="btn_collapse collapse-button"
                        size="sm"
                        ><div>
                          <b-icon
                            icon="layers-fill"
                            aria-hidden="true"
                            scale="1.2"
                          ></b-icon>
                          {{ areas.name }}:
                        </div></b-button
                      >
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + index + 3"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body>
                        <div class="p-1">
                          <div
                            class="mt-1 rounded style-2"
                            style="
                              overflow-y: scroll;
                              overflow-x: scroll;
                              max-height: 50vh;
                              background: #c7c7c7;
                            "
                          >
                            <v-jstree
                              :data="areas.data"
                              show-checkbox
                              multiple
                              allow-batch
                              whole-row
                              @item-click="itemClick_flood"
                              >
                            </v-jstree>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        </div>
        <b-row class="mt-auto w-100">
          <b-col cols="6">
            <div class="mt-3 px-1">
              <div class="shadow d-flex justify-content-center">
                <b-button
                  size="sm"
                  :disabled="reset_layers_disabled"
                  variant="primary"
                  class="w-100"
                  @click="reset_layers"
                  style="padding: 0.175rem 1rem!important;"
                >
                  <b-icon
                    icon="arrow-counterclockwise"
                    aria-hidden="true"
                  ></b-icon
                  ><br />
                 {{content_all[this.$store.state.languege].reset_layers}}</b-button
                >
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="mt-3 px-1">
              <div class="shadow">
                <b-button
                  size="sm"
                  :disabled="table_button_disabled"
                  variant="success"
                  class="w-100"
                  @click="show_table = !show_table"
                  style="padding: 0.175rem 1rem!important;"
                >
                  <b-icon icon="table" aria-hidden="true"></b-icon><br />
                  {{content_all[this.$store.state.languege].features_table}}</b-button
                >
              </div>
            </div>
          </b-col>
         <!-- <b-col cols="4">
            <div class="mt-3 px-1">
              <div class="shadow">
                <b-button
                  size="sm"
                  :disabled="true"
                  variant="danger"
                  class="w-100"
                  @click="generatePdf"
                  ><i class="fas fa-file-pdf"></i><br />
                  Export Data</b-button
                >
              </div>
            </div>
          </b-col>-->
        </b-row>
       
      </b-col>
      <b-col
        v-draggable="{ handle: 'strong' }"
        v-if="show_table"
        :lg="3"
        md="12"
        class="position-absolute shadow-lg table-cont "
        style=""
        ref="switch_button"
        id="switch_button_id"
      >
        <div>
          <strong
            ><div class="bg-dark w-100 p-2 text-center">
              <b-icon
                class="revue-draggable"
                icon="arrows-move"
                scale="2"
                variant="light"
              ></b-icon></div
          ></strong>
          <button
            @click="close()"
            type="button"
            class="position-absolute btn btn-primary btn-sm"
            style="
              z-index: 1002;
              top: 0px;
              right: 0px;
              font-size: 17px;
              float: right;
              padding: 0.3rem 0.8rem !important;
            "
          >
            &times;
          </button>
          <div class="">
            <b-card no-body style="min-height: 50vh">
              <b-tabs card small no-fade>
                <div v-for="(i, index, num) in items" :key="num">
                  <!--position-fixed w-75-->
                  <b-tab
                    class=""
                    ref="tabs"
                    v-if="booleanOflayerArray[index] && i"
                    :title="nameOflayerArray[index] + ' (' + i.length + ')'"
                  >
                    <b-card-text>
                      <div class="d-flex">
                        <b-col class="d-flex justify-content-start my-1" lg="6">
                        
                        </b-col>
                        <b-col class="justify-content-center my-1" lg="6">
                          <b-input-group size="sm" class="shadow">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''"
                                >Clear</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                        </b-col>
                      </div>
                      <div
                        class="shadow style-2"
                        style="
                          overflow-y: scroll;
                          overflow-x: hidden;
                          max-height: 35vh;
                        "
                      >
                        <b-table
                          ref="table"
                          striped
                          hover
                          :filter="filter"
                          :items="items[index]"
                          @row-clicked="myRowClickHandler"
                          :fields="fields[index]"
                          select-mode="single"
                          selectable
                        >
                          <template #cell(polygon)="data">
                            <span v-html="data.value" v-if="!checked"></span>
                          </template>
                          <template #cell(selected)="{ rowSelected }">
                            <template v-if="rowSelected">
                              <span aria-hidden="true">&check;</span>
                              <span class="sr-only">Selected</span>
                            </template>
                            <template v-else>
                              <span aria-hidden="true">&nbsp;</span>
                              <span class="sr-only">Not selected</span>
                            </template>
                          </template>
                        </b-table>
                      </div>
                    </b-card-text>
                  </b-tab>
                </div>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="show_photo"
        :lg="col_sidebar_right"
        md="12"
        class="position-absolute shadow-lg"
        style="z-index: 1001; top: 0; right: 0px"
      >
        <div class="bg-secondary rounded-bottom shadow">
          <div class="p-2">
            <button
              @click="show_photo = !show_photo"
              type="button"
              class="position-absolute btn btn-primary btn-sm"
              style="
                z-index: 1002;
                top: 0px;
                right: 0px;
                font-size: 17px;
                float: right;
              "
            >
              &times;
            </button>
            <div style="width: 100%; height: auto">
              <img :src="photo_url" style="width: 100%; object-fit: cover" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script>
import L from "leaflet";
import axios from "../axios-auth";
import axios_all from "axios";


import VJstree from "vue-jstree";


import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
//import jsPDF from "jspdf";
//import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
//import "vue-slider-component/dist-css/vue-slider-component.css";
//import "vue-slider-component/theme/default.css";
//import {LCanvasMarker} from 'vue2-leaflet-canvas-marker';
//import  Functions  from "../../public/json/tree_flood.json";
//import  {Functions}  from "../../public/js/tree_flood";

//import { areas_array_function } from "../../public/js/areas_array";
//import  {seismic_function} from "../../public/js/seismic";
//import  {fire_function}  from "../../public/js/fire";
import lang from "./json/languege.json"
import Gallery from "vue-cover-gallery";
//import lagend from "./legend.vue"

export default {
  components: {
    VJstree,
    //VueSlider,
    Gallery,
    "l-control-polyline-measure": LControlPolylineMeasure,
    //"l-canvas-marker":LCanvasMarker
    //lagend
  },
  data() {
    return {
      reload_layers:true,
      general_geoserver_bool:{},
      content_all:lang,
      show_ignition_points:false,
      valuemultiselect: null,
      optionsmultiselect: ['list', 'of', 'options'],
      colors_autopsies_fire:{'houses':'#ffc400','akatharistes_perioxes':'#05a62d','protection':'#ff008c','roads':'#00d0ff','shelters':'#c300ff'},
      colors_kliseis_flood:{2006:'#fddfe0',2007:'#fddfe0',2008:'#fddfe0',2009:'#fddfe0',2010:'#eba59b',2011:'#eba59b',2012:'#eba59b',2013:'#eba59b',2014:'#ce6f60',2015:'#ce6f60',2016:'#ce6f60',2017:'#ce6f60',2018:'#984237',2019:'#984237',2020:'#984237',2021:'#984237',2022:'#890c0c',2023:'#890c0c'},
      legend_object:[],
  
      array_photos_lat_lng: [],
      tree_reload: true,
      areas_array: null,

      location_array: [],
      pdf_autopsies_name: null,
      show_modal_data:true,
      images: [
        {
          title: "title 1",
          description: "des 1",
          href: "/autopsies/WFS_F1/WFS_F1.1.png",
        },
        {
          title: "title 2",
          description: "des 2",
          href: "/autopsies/WFS_F1/WFS_F1.2.jpg",
        },
      ],
      valueOpacity_obj: {
        vulnerability: 90,
        exposure: 90,
        hazard: 90,
        risk: 90,
        seismic: 90,
      },

      url_test: null,
      legend_values: [],
      object_legend: [],
      show_legend: false,
      data_tree_flood: null,
      data_tree_seismic: null,
      data_tree_fire: null,
      color_selectedArea: "#ababab",
      show_autopsies: true,
      show_photo: false,
      show_seismic_drill: {},
      show_seismic_xoroi_katafigis:{},
      show_seismic_photos: {},
      show_fire_autopsies_points: false,
      risk_data: null,
      drill_data: null,
      xoroi_katafigis:null,
      photos_data: null,
      autopsies_data: null,
      wfsp_data: null,
      show_modal_autopsies: false,
      checked_ayer_of_hazard: false,
      select_hazard_with_wms_seismic: false,
      select_hazard_with_wms_flood: false,
      select_hazard_with_wms_fire: false,
      fillOpacity: 0,
      layer_flood: null,
      wmsLayer: {
        url: "https://geoserver.idcom.gr/geoserver/perifereia/wms",
        visible: true,
        format: "image/png",
        layers: "geodata_seismic_elliniko",
        transparent: true,
        show_wms_seismic: false,
        valueOpacity: 100,
      },
      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "OpenStreetMap.HOT",
          visible: false,
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Tiles style by <a href="https://www.hotosm.org/" target="_blank">Humanitarian OpenStreetMap Team</a> hosted by <a href="https://openstreetmap.fr/" target="_blank">OpenStreetMap France</a>',
          url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        },

        {
          name: "OpenTopoMap",
          visible: false,
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
        },
        {
          name: "Stadia.AlidadeSmoothDark",
          visible: false,
          url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png?api_key=9a9206be-79a9-461b-986f-f9b419cdb7ea",
          attribution:
            '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        },
        {
          name: "CyclOSM",
          visible: false,
          url: "https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
          attribution:
            '<a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: "Esri.WorldImagery",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
          attribution:
            "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
        },
      ],
      photo_url: null,
      area_of_interest_data: null,
      locations:null,
      selected_hazard: null,
      selected_area_of_interest: [],
      AreaOfInterest: null,
      municipalities: null,
      selectedMunicipalities: null,
      save_favourite_disabled: true,
      area_of_interest: [],
      output: null,
      selected_area_info: null,
      html_info: null,
      info_contoler: false,
      input_string: "all",
      radius: null,
      reset_layers_disabled: true,
      col_map: 9,
      col_sidebar_right: 3,
      showSidebar: true,
      coordinates_map_center: null,
      coordinates_map_center_location: null,
      radius_coordinates_map_center_location: null,
      show_my_location: false,
      table_button_disabled: true,
      coordinates_map: null,
      type_of_layer: null,
      checked: false,
      popup_text: null,
      filter: null,
      height_map: "100vh",
      show_table: false,
      test: { icon: L.icon({ iconUrl: "/img/camera.png" }) },
      colorsArray: [
        { color: "#3388ff" },
        {
          color: "#A90913",
        },
        { color: "#F36932" },
        {
          color: "#28A909",
        },
        {
          color: "#1DE3D7",
        },
        {
          color: "#C51DE3",
        },
      ],
      geojson: null,
      show: true,
      //nameOflayerArray:['roadslatlngs','hospitalslatlngs',"prohibited_areaslatlngs","east_attica_cameraslatlngs","railwaylineslatlngs","railwaystationslatlngs"],
      nameOflayerArray: [], //prosthetw ta layers pou exoun epilexthei
      flood_hydropraphic_data: [],
      flood_hydropraphic_names: {},
      num_of_hydropraphic:[],
      flood_critical_points: {},
      flood_critical_points_names: [],
      boolean_flood_critical_points: [false, false, false, false, false, false],
      boolean_flood_shelters:[false],
      flood_shelters: [],
      flood_shelters_names: [],
      boolean_flood_kliseis_pyros:{},
      flood_kliseis_pyros:[],
      flood_kliseis_pyros_names:{},
      flood_kliseis_pyros_canvas:[],
      boolean_flood_hydropraphic_data: [false, false,false,false],
      color_flood_hydropraphic: {'natural':"#1d2ae3",'artificial':"#b21de3","fysiko":"#1d2ae3","texnito":"#b21de3",'Natural':"#1d2ae3",'Artificial':"#b21de3"},
      layers_flood: {},
      layers_autopsies: [],
      layers_seismic: {},
      layers_fire: {},
  
      booleanOflayerArray: new Array(48).fill(false),
      boolean_layers_flood: {},//new Array(70).fill(false),
      boolean_layers_autopsies: new Array(80).fill(false),
      boolean_layers_seismic: {}, //new Array(30).fill(false),
      boolean_layers_fire: {},//new Array(80).fill(false),
      favourite_layers_obj: {},
      favourite_layers_string: null,
      autopsies_fire_array:{},
      bool_autopsies_fire_array:[],
      data: [
        {
          text: "Utilities",
          value: "Utilities",

          children: [
            {
              text: "admie Lines",
              icon: "fas fa-layer-group",
              value: "admie_line",
              //"value": pois_layers[rr].type_l03,
              dropDisabled: true,
              selected: false,
              disabled: false,
            },
            {
              text: "admie Towers",
              icon: "fas fa-layer-group",
              value: "admie_towers",
              //"value": pois_layers[rr].type_l03,
              dropDisabled: true,
              selected: false,
              disabled: false,
            },
          ],
        },
      ],

      options: {
        multiple: true,
        checkbox: true,
        checkOnSelect: true,
        parentSelect: false,
      },
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: "https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
      zoom: 11,
      center: [38.038393, 23.749695],
      //bounds: [[38.52453288748689,24.91012573242188],[37.48684571271661,22.66342163085938]],
      bounds: [
        [38.1837884127241, 24.102561682375594],
        [37.68826836479753, 23.303306311281844],
      ],
      layersData: {},
      Flood_Data: {},
      polygonsData: {},
      polyline: {},
      ignition_point: [0, 0, 0, 0, 0, 0, 0, 0, 0],
      ignition_points: [],
      typeOfData: [],
      // ArrayOfData:[],
      tst: false,
      nameOflayer: null,
      evt: null,
      name: null,
      items: [],
      fields: [],
      first_time: true,
      rulerOptions: {
        // Leaflet control position option
        circleMarker: {
          // Leaflet circle marker options for points used in this plugin
          color: "red",
          radius: 2,
        },
        lineStyle: {
          // Leaflet polyline options for lines used in this plugin
          color: "red",
          dashArray: "1,6",
        },
        lengthUnit: {
          // You can use custom length units. Default unit is kilometers.
          display: "km", // This is the display value will be shown on the screen. Example: 'meters'
          decimal: 2, // Distance result will be fixed to this value.
          factor: null, // This value will be used to convert from kilometers. Example: 1000 (from kilometers to meters)
          label: "Distance:",
        },
        angleUnit: {
          display: "&deg;", // This is the display value will be shown on the screen. Example: 'Gradian'
          decimal: 2, // Bearing result will be fixed to this value.
          factor: null, // This option is required to customize angle unit. Specify solid angle value for angle unit. Example: 400 (for gradian).
          label: "Bearing:",
        },
      },
    };
  },
  computed: {
    optionsGeoJson() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    optionsGeoJsonHazard() {
      return {
        onEachFeature: this.onEachFeatureFunctionHazard,
      };
    },
    optionsGeoJsonSeismic() {
      return {
        onEachFeature: this.onEachFeatureFunctionSeismic,
      };
    },
    optionsGeoJsonFloodShelters(){
      return {
        onEachFeature: this.onEachFeatureFunctionFloodShelters,
      };
    },
    optionsGeoJsonFlood() {
      return {
        onEachFeature: this.onEachFeatureFunctionFlood,
      };
    },
    optionsGeoJsonFloodHydropraphic() {
      return {
        onEachFeature: this.onEachFeatureFunctionFloodHydropraphic,
      };
    },
    optionsGeoJsonAutopsies() {
      return {
        onEachFeature: this.onEachFeatureFunctionAutopsies,
      };
    },
    onEachFeatureFunctionFloodShelters(){
      return (feature, layer) => {
        layer.setIcon(
            L.icon({
              iconUrl: "/img/shelters.png",
              iconSize: [20, 20],
            })
          );
        layer.bindTooltip("<div>Refuge Areas:"+feature.properties.name, {
            permanent: false,
            sticky: true,
          });
      }
    },
    onEachFeatureFunctionFloodHydropraphic() {
      return (feature, layer) => {
        var name=feature.properties.name?'Name:'+feature.properties.name:''
        if (feature.properties.label && (feature.properties.koiti==='texnito' || feature.properties.koiti==='artificial')) {
          ////console.log(feature)
            console.log(feature.properties.name)
          
            layer.bindTooltip(
            "<div>"+name+"<br>Hydrographic network: Artificial<br>" +
              feature.properties.label +
              "</div>",
            { permanent: false, sticky: true }
          );
        } else {
          if(feature.properties.koiti==='texnito' || feature.properties.koiti==='artificial'){
              layer.bindTooltip(
            "<div>"+name+"<br>Hydrographic network: Artificial<br></div>",
            { permanent: false, sticky: true }
          );
          }else{
             layer.bindTooltip("<div>"+name+"<br>Hydrographic network: Natural</div>", {
            permanent: false,
            sticky: true,
          });
          }
         
        }
        layer.on("mouseover", function () {
          this.setStyle({
            color: "red", //or whatever style you wish to use;
          });
        });
        layer.on("mouseout", function () {
          this.setStyle({
            color: feature.properties.color,
          });
        });
      };
    },
    onEachFeatureFunctionFlood() {
      var v = this;
      return (feature, layer) => {
       // //console.log(feature.properties)
        var name = feature.properties.name.split("-!");
        if (feature.properties.name.includes("1st Priority")) {
          layer.setIcon(
            L.icon({
              iconUrl: "/img/" + name[0] + ".png",
              iconSize: [20, 20],
            })
          );
        } else {
          layer.setIcon(
            L.icon({
              iconUrl: "/img/" + name[1] + ".png",
              iconSize: [20, 20],
            })
          );
        }

        if (feature.properties.folderpath) {
          if (feature.properties.name.includes("1st Priority")) {
            layer.bindTooltip(
              "<div>" +
                name[0] +
                "<br><strong>Name:</strong>" +
                feature.properties.folderpath +
                "<br><strong>Data:</strong>yes</div>",
              { permanent: false, sticky: true }
            );
          } else {
            layer.bindTooltip(
              "<div>" +
                name[1] +
                "<br><strong>Name:</strong>" +
                feature.properties.folderpath +
                "<br><strong>Data:</strong>yes</div>",
              { permanent: false, sticky: true }
            );
          }
          layer.on("click", function (e) {
            ////console.log(e)
            v.LoadImgAndPdf(e, feature, v);
          });
        } else {
          layer.bindTooltip(
            "<div>" + name[0] + "<br>Data:no</div>",
            { permanent: false, sticky: true }
          );
        }
      };
    },

    onEachFeatureFunctionHazard() {
      var v = this;
      return (feature, layer) => {
        ////console.log(feature.properties.name)
        layer.setStyle({ fillOpacity: 0.08 })
        if (v.selected_area_of_interest.includes(feature.properties.name)) {
          layer.setStyle({ color: "#C6212D", fillOpacity: 0 });
        }
        
        ////console.log(feature)
        ////console.log(layer)

        layer.on("click", function (e) {
          ////console.log(e)

          e.sourceTarget.setStyle({ color: "#C6212D", fillOpacity: 0 });
         // //console.log(feature.properties.name)
         

          if (!v.selected_area_of_interest.includes(feature.properties.name)) {
            v.selected_area_of_interest.push(feature.properties.name)
            v.ChangeSelectedAreaOfInterest();
          }
         
        });
      };
    },

    onEachFeatureFunctionAutopsies() {
      var v = this;

      return (feature, layer) => {
        //gia kathe layer (pou einai geojson) orise sto kentro tou ena icon
        if (feature.geometry.type === "MultiPoint") {
          layer.eachLayer(function (e) {
            ////console.log(e)
            e.setIcon(
              L.icon({
                iconUrl:
                  "/img/" +
                  feature.properties.type +
                  "-" +
                  feature.properties.color +
                  ".png",
                iconSize: [22, 22],
              })
            );
          });
        }
        if (feature.geometry.type === "Point") {
          layer.setIcon(
            L.icon({
              iconUrl:
                "/img/" +
                feature.properties.type +
                "-" +
                feature.properties.color +
                ".png",
              iconSize: [22, 22],
            })
          );
        }

        layer.bindTooltip(
          "<div>" +
            feature.properties.type +
            ":" +
            feature.properties.name +
            "</div>",
          { permanent: false, sticky: true }
        );

        layer.on("click", async function (e) {
          v.LoadImgAndPdf(e, feature, v);
        });
      };
    },

    onEachFeatureFunctionSeismic() {
      var v = this;

      return (feature, layer) => {
      
        //gia kathe layer (pou einai geojson) orise sto kentro tou ena icon
        if (feature.geometry.type === "MultiPoint") {
          layer.eachLayer(function (e) {
            ////console.log(e)
            e.setIcon(
              L.icon({
                iconUrl: "/img/" + feature.properties.type + ".png",
                iconSize: [22, 22],
              })
            );
          });
        }
        if (feature.geometry.type === "Point") {
          layer.setIcon(
            L.icon({
              iconUrl: "/img/" + feature.properties.type + ".png",
              iconSize: [22, 22],
            })
          );
        }

        // //console.log(layer)

        //prosthetw to tooltip gia to kathe marker
        if (feature.properties.name != undefined) {
          layer.bindTooltip(
            "<div>" +
              feature.properties.type +
              ":" +
              feature.properties.name +
              "</div>",
            { permanent: false, sticky: true }
          );
        } else {
          layer.bindTooltip("<div>" + feature.properties.type + "</div>", {
            permanent: false,
            sticky: true,
          });
        }

        const hazard = v.selected_hazard.toLowerCase();

        const area_interest = feature.properties.location.toLowerCase();

        layer.on("click", async function (e) {
          v.$refs.features.mapObject.openTooltip([e.latlng.lat, e.latlng.lng]);

          //var name=e.target.feature.properties.name
          var name =
            hazard + "/" + area_interest + "/" + feature.properties.name;

         v.show_img_right(name,v)
        });
      };
    },
    onEachFeatureFunction() {
      var v = this;
      
      return (feature, layer) => {
        ////console.log(layer)
        //layer.setIcon(L.icon({iconUrl: '/img/camera.png'}))

        //gia kathe layer (pou einai geojson) orise sto kentro tou ena icon
       
        
        if (feature.geometry.type === "MultiPoint") {
          layer.eachLayer(function (e) {
           
            e.setIcon(
            
              L.icon({
                iconUrl: "/img/" + feature.properties.type + ".png",
                iconSize: [22, 22],
              })
            );
            
          });
        }
        if (feature.geometry.type === "Point") {
          //console.log(feature.properties.type)
          
         

          layer.setIcon(
            L.icon({
              iconUrl: "/img/" + feature.properties.type + ".png",
              iconSize: [22, 22],
            })
          );
          
        }

        // //console.log(layer)

        //prosthetw to tooltip gia to kathe marker
        if (feature.properties.name != undefined) {
          layer.bindTooltip(
            "<div>" +
              feature.properties.type +
              ":" +
              feature.properties.name +
              "</div>",
            { permanent: false, sticky: true }
          );
        } else {
          layer.bindTooltip("<div>" + feature.properties.type + "</div>", {
            permanent: false,
            sticky: true,
          });
        }

        //allilepidrash map me table. Otan patas ena market panw sto xarth sou kanei focus sto element tou table
        layer.on("click", function (e) {
          ////console.log('here',e)
          var index;
          var tbody;
          var all_rows;
          for (var i = 0; i < v.$refs.tabs.length; i++) {
            ////console.log(v.$refs.tabs[i])
            ////console.log(e.sourceTarget.feature.properties.type)

            //kleinw ola ta tabs kai krataw anoixto mono sto opoio anikei to element pou patithike
            //sthn metablith type exw prostesi sto kathe element se poia katigoria aniki (des line 68)
            if (
              v.$refs.tabs[i].title.includes(
                e.sourceTarget.feature.properties.type
              )
            ) {
              v.$refs.tabs[i].localActive = true;

              index = i;
            } else {
              v.$refs.tabs[i].localActive = false;
            }

            //kanw mple to background apo to element pou epilexthike kai aspro ola ta alla
            tbody = v.$refs.table[i].$el.querySelector("tbody");
            all_rows = tbody.querySelectorAll("tr");
            all_rows.forEach((index) => {
              index.classList.remove("table-active");

              index.style.backgroundColor = "white";
            });
          }

          //scroll to table element
          tbody = v.$refs.table[index].$el.querySelector("tbody");
          ////console.log(v.$refs)
          ////console.log(v.$refs)
          const row =
            tbody.querySelectorAll("tr")[e.sourceTarget.feature.properties.num];
          ////console.log(row)
          row.style.backgroundColor = "#cfe2ff";
          setTimeout(() => {
            row.scrollIntoView({ behavior: "smooth" });
          }, 200);
        });
      };
    },
  },
  async created() {
    //console.log(this.$store.state.languege)
    //console.log(this.content_all[this.$store.state.languege])
    //console.log(this.content_all[this.$store.state.languege].general_layers)
    //var languege_obj=await axios_all.get('/json/languege.json')
    //this.content_all=languege_obj[this.$store.state.languege]
    //this.favourite_layers_string='{ "Camps":"camps","Hostels":"hostels"}'
    //this.favourite_layers_obj=JSON.parse(this.favourite_layers_string)

    ////console.log(re)
  
    await this.createTree();
    var risk_all = await axios.get("risk_names/");
    this.risk_data = risk_all.data;
    var drill_all = await axios.get("seismic/drill/");
    ////console.log(drill_all)
    this.drill_data = drill_all.data;
    //console.log( this.drill_data)

    var xoroi_katafigis_all = await axios.get("seismic/xoroi-katafigis/");
    ////console.log(drill_all)
    this.xoroi_katafigis = xoroi_katafigis_all.data;
    //console.log( this.xoroi_katafigis)

    var photos_all = await axios.get("seismic/photos/");
    ////console.log(photos_all)
    this.photos_data = photos_all.data;
    //console.log('photos:',this.photos_data)
    var autopsies_all = await axios.get("autopsies/");
    ////console.log(photos_all)
    this.autopsies_data = autopsies_all.data;
    ////console.log(this.autopsies_data)

    var wfsp_all = await axios.get("wfsp/");
    ////console.log(photos_all)
    this.wfsp_data = wfsp_all.data;


    ////console.log(this.wfsp_data)

   
    this.tst = true;

    
  },

  methods: {
  changeRange(event,row_click_data,hazard){
    console.log(hazard)
    var hazard_obj
    if(hazard==='FIRE'){
      hazard_obj=this.layers_fire
    }else if(hazard==='FLOOD'){
      hazard_obj=this.layers_flood
    }else{
      hazard_obj=this.layers_seismic
    }
    var split_layer
    var row_one_data
    console.log(hazard_obj)
    if(row_click_data.children.length){
      for(var mm=0; mm<row_click_data.children.length; mm++){
      row_one_data=row_click_data.children[mm]
      row_one_data.opacity=event.target.value/100
        for(var bb in hazard_obj){
        //console.log(this.layers_flood[bb])
       
          
          //console.log(row_click_data.value[vv].layer.split('-!')[0])
          for(var cc=0; cc<hazard_obj[bb].length; cc++){
            for(var vv=0; vv<row_one_data.value.length; vv++){
          
          //if(this.layers_flood[bb].includes(row_click_data.value[vv].layer.split('-!')[0])){
            if(hazard_obj[bb][cc].includes(row_one_data.value[vv].layer.split('-!')[0])){
              //split_layer=row_one_data.value[vv].layer.split('-!')
              
              split_layer=hazard_obj[bb][cc].split('-!')
              //console.log(split_layer)
              hazard_obj[bb][cc]=split_layer[0]+'-!'+split_layer[1]+'-!opacity:'+event.target.value/100
              if(hazard_obj[bb][cc].includes(row_one_data.value[vv].layer.split('-!')[0]+'-!'+row_one_data.value[vv].layer.split('-!')[1]+'-!')){
                row_one_data.value[vv].layer=split_layer[0]+'-!'+split_layer[1]+'-!opacity:'+event.target.value/100
              }
            }
            }
          }

        }
      }
    }else{
      console.log(row_click_data)

      row_one_data=row_click_data
      row_one_data.opacity=event.target.value/100
        for(var bl in hazard_obj){
        //console.log(this.layers_flood[bb])
       
          
          //console.log(row_click_data.value[vv].layer.split('-!')[0])
          for(var cl=0; cl<hazard_obj[bl].length; cl++){
            for(var vl=0; vl<row_one_data.value.length; vl++){
          
          //if(this.layers_flood[bb].includes(row_click_data.value[vv].layer.split('-!')[0])){
            if(hazard_obj[bl][cl].includes(row_one_data.value[vl].layer.split('-!')[0])){
              //split_layer=row_one_data.value[vv].layer.split('-!')
              
              split_layer=hazard_obj[bl][cl].split('-!')
              //console.log(split_layer)
              hazard_obj[bl][cl]=split_layer[0]+'-!'+split_layer[1]+'-!opacity:'+event.target.value/100
              if(hazard_obj[bl][cl].includes(row_one_data.value[vl].layer.split('-!')[0]+'-!'+row_one_data.value[vl].layer.split('-!')[1]+'-!')){
                row_one_data.value[vl].layer=split_layer[0]+'-!'+split_layer[1]+'-!opacity:'+event.target.value/100
              }
            }
            }
          }

        }
    }
    
    

    
    this.reload_layers=false
    setTimeout(()=>{this.reload_layers=true},200)
    
    if(hazard==='FIRE'){
      this.layers_fire=hazard_obj
    }else if(hazard==='FLOOD'){
      this.layers_flood=hazard_obj
    }else{
      this.layers_seismic= hazard_obj
    }

  },
   async getTreeObjects(){
      
      var lang=this.$store.state.languege
      this.data_tree_flood=await axios_all.get('/json/'+lang+'/tree_flood.json')
      this.data_tree_seismic=await axios_all.get('/json/'+lang+'/tree_seismic.json')
      this.data_tree_fire=await axios_all.get('/json/'+lang+'/tree_fire.json')
      this.data_tree_flood= this.data_tree_flood.data
      this.data_tree_seismic= this.data_tree_seismic.data
      this.data_tree_fire= this.data_tree_fire.data

      var autopsies=await axios_all.get('/json/'+lang+'/sxinos.json')
      var vilia=await axios_all.get('/json/'+lang+'/vilia.json')
      var varimpompi=await axios_all.get('/json/'+lang+'/varimpompi.json')
      
      ////console.log(this.data_tree_fire)
      //this.data_tree_fire[0].disabled=false
      this.areas_array= [
            {
              name:'Δασ. Πυρκαγιά Σχοίνος 19/5/2021',
              //data:autopsies_function.autopsies()
              data:autopsies.data
            },
            {
              name:'Δασ. Πυρκαγιά Βίλια 16/8/2021',
              data:vilia.data
            },
            {
              name:'Δασ. Πυρκαγιά Βαρυμπόμπης 3/8/2021',
              data:varimpompi.data
            }       
          ]

      this.ignition_points =await axios_all.get('/json/ignition_points.json')
      this.ignition_points=this.ignition_points.data
      this.ignition_point=new Array(this.ignition_points.length).fill(0)
      console.log(this.ignition_points)
      ////console.log(this.ignition_point)
      
      /*axios_all.get('/js/tree_flood.json').then((response)=>{
        v.data_tree_seismic=response.data
      })
      axios_all.get('/js/tree_flood.json').then((response)=>{
        v.data_tree_fire=response.data
      })*/
      
    },
    
    show_img_right(name,v){
      ////console.log(name)
      this.tst=false
      axios
            .get("view-pdf/", { params: { name: name }, responseType: "blob" })
            .then((response) => {
              //Create a Blob from the PDF Stream
              ////console.log(name.includes('.jpg'))
              var type;
              if (name.includes(".jpg")) {
                type = "image/jpeg";
              } else {
                type = "application/pdf";
              }
              ////console.log(name)
              const file = new Blob(
                [response.data],
                //{type: 'application/pdf'});
                { type: type }
              );
              ////console.log(file)
              //Build a URL from the file

              const fileURL = URL.createObjectURL(file);
              //Open the URL on new Window

              if (!name.includes(".jpg")) {
                window.open(fileURL);
              } else {
                v.photo_url = fileURL;
                v.show_photo = true;
              }
              // window.open(fileURL);
              v.tst=true
            });
    },
   
   
    async LoadImgAndPdf(e, feature, v) {
      //otan pataw click se kapoio shmadi anoigei ena tooltip me marker
      v.$refs.features.mapObject.openTooltip([e.latlng.lat, e.latlng.lng]);
      v.show_modal_autopsies = true;
      v.images = [];
      ////console.log(feature.properties.name)
      var pri = feature.properties.name.split("-!");
      var priority = pri[1];
      var path
      if(priority){
        path =
        feature.properties.location +
        "/" +feature.properties.place+'/'+
        'priority' +
        "/" +
        feature.properties.folderpath;
      }else{
         path =
        feature.properties.location +
        "/" +
        feature.properties.folderpath;
      }
      //console.log(path);
      //epistrefei ta periexomena (img kai pdf) apo tous fakelous pou exei epilexthei
      var array_file_names = await axios.get("array-files/", {
        params: { name: path },
      });

      ////console.log(array_file_names)
      var array_file_names_data = array_file_names.data;
      v.pdf_autopsies_name =null
      v.show_modal_data=true
      for (var yy in array_file_names_data) {
        var name = path + "/" + array_file_names_data[yy];
        ////console.log(name)
        var resp = await axios.get("open-file/", {
          params: { name: name },
          responseType: "blob",
        });
        // //console.log(resp)
        //Create a Blob from the PDF Stream
        ////console.log(name.includes('.jpg'))
        var type;
        if (name.includes(".jpg")) {
          type = "image/jpeg";
        } else {
          type = "application/pdf";
        }
        ////console.log(name)
        const file = new Blob(
          [resp.data],
          //{type: 'application/pdf'});
          { type: type }
        );
        ////console.log(file)
        //Build a URL from the file

        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window

        if (name.includes(".pdf")) {
          v.pdf_autopsies_name = fileURL;
        } else {
          v.images.push({ href: fileURL });
        }
      }
      this.show_modal_data=false
    },
    Point_img_Click(url) {
      this.show_photo = true;
      //console.log(url)
      this.show_img_right(url,this)
      //this.photo_url = url;
    },
    ConvertDMSToDD(degrees, minutes, seconds) {
      var dd = degrees + minutes / 60 + seconds / (60 * 60);
      return dd;
    },
    myLocation() {
      this.show_my_location = !this.show_my_location;

      if (navigator.geolocation && this.show_my_location) {
        navigator.geolocation.getCurrentPosition((position) => {
          this.position = position.coords;
          ////console.log(position)
          var lat = this.position.latitude;
          var lng = this.position.longitude;
          this.coordinates_map_center_location = [lat, lng];
          this.radius_coordinates_map_center_location = this.position.accuracy;
          this.$refs.map.mapObject.flyTo([lat, lng], 15, 30);
        });
      }
    },

    open_pdf() {
      window.open(this.pdf_autopsies_name);
    },

    async ChangeSelectedHazard() {
      //this.tree_reload=false

      //this.clear_layers()

      this.color_selectedArea = "#003b5d";
      // //console.log(this.selected_hazard)
      var area_of_interest_all = await axios.get("area_of_interest/", {
        params: {
          name_hazard: this.selected_hazard,
        },
      });

      ////console.log(area_of_interest_all)
      this.area_of_interest_data = area_of_interest_all.data;
      //console.log( this.area_of_interest_data)
      this.locations=[]
      for(var tt=0; tt<this.area_of_interest_data.length; tt++){
        this.locations.push(this.area_of_interest_data[tt].hazname)
      }
      ////console.log(this.locations)
      this.selected_area_of_interest=[]
      this.select_hazard_with_wms_seismic = false;
      this.select_hazard_with_wms_flood = false;
      this.select_hazard_with_wms_fire = false;
      //this.selected_area_of_interest=null
      /*if (this.selected_area_of_interest !== null) {
        this.selected_area_of_interest =this.area_of_interest_data[0].hazname + "_0";
        this.ChangeSelectedAreaOfInterest();
      }*/
      await this.clear_layers();
      /*if (this.selected_hazard === "FIRE") {
        this.data_tree_risk = this.data_tree_fire;
  
      } else if (this.selected_hazard === "FLOOD") {
        this.data_tree_risk = this.data_tree_flood;
      }*/
    },
    async ChangeSelectedAreaOfInterest() {
      //this.AreaOfInterest=this.area_of_interest[this.selected_area_of_interest][0].re_geom
      
      
      //this.input_string = this.selected_area_of_interest.split("_")[0];
      //this.input_string = this.selected_area_of_interest;
     
      ////console.log('here')
      this.reset_layers_disabled = false;
     
      //await this.clear_layers();

      if (this.area_of_interest_data[0].haztype === "SEISMIC") {
        

        this.select_hazard_with_wms_seismic = true;
        /*this.$refs.map.mapObject.flyTo(
          [37.907783972444946, 23.74179840087891],
          12,
          30
        );*/
      } else if (this.area_of_interest_data[0].haztype === "FLOOD") {
        

        this.select_hazard_with_wms_flood = true;
        /*this.$refs.map.mapObject.flyTo(
          [37.938944119492504, 23.750724792480472],
          12,
          30
        );*/
       
      } else {
        

        this.select_hazard_with_wms_fire = true;
        /*this.$refs.map.mapObject.flyTo(
          [37.88534981202585, 24.00375366210938],
          12,
          30
        );*/
      }

      //this.tree_reload=false

      /*if (this.nameOflayerArray.length > 0) {
        this.filtering_polygon(this.input_string, null, false);
      }*/
    },
    clear_layers() {
      //this.tree_reload=false
      //this.areas_array = areas_array_function.areas_array();
      //this.tree_reload=false
      this.show_ignition_points=false;
      this.select_hazard_with_wms_flood = false;
      this.select_hazard_with_wms_fire = false;
      this.select_hazard_with_wms_seismic = false;
      this.wmsLayer.show_wms_seismic = false;
      this.show_seismic_photos = {};
      this.show_fire_autopsies_points = false;
      this.show_seismic_drill = {};
      this.show_seismic_xoroi_katafigis = {};
      this.location_array = [];

      this.flood_critical_points_names = [];
      this.flood_critical_points ={};
      //this.flood_hydropraphic_data = [];
      this.flood_hydropraphic_names = {};
      this.boolean_flood_shelters=[false]
      this.boolean_flood_kliseis_pyros={}
      this.boolean_flood_critical_points = [
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.boolean_flood_hydropraphic_data = [false, false,false,false];

      
      for (var ff = 0; ff < this.bool_autopsies_fire_array.length; ff++) {
        this.bool_autopsies_fire_array[ff] = false;
      }
      this.layers_flood={}
      this.boolean_layers_flood={}
     

      this.layers_seismic={}
      this.boolean_layers_seismic={}
    
      this.layers_fire={}
      this.boolean_layers_fire={}
     // this.ignition_point = [0, 0, 0, 0, 0, 0, 0, 0, 0];
     this.ignition_point=new Array(this.ignition_points.length).fill(0)
      for (var ww = 0; ww < this.boolean_layers_autopsies.length; ww++) {
        this.boolean_layers_autopsies[ww] = false;
      }
       
      //this.data_tree_flood = Functions.tree_flood();
      
      

      //this.data_tree_seismic = seismic_function.seismic();
      ////console.log(this.data_tree_flood)
      //this.data_tree_fire = fire_function.fire();
      this.getTreeObjects()
      
      this.array_photos_lat_lng=[]
      this.layers_fire={}
      //this.data_tree_giorgis=giorgis_function.giorgis()
      //this.data_tree_autopsies=autopsies_function.autopsies()

      if (this.selected_hazard === "FIRE") {
        this.data_tree_risk = this.data_tree_fire;
      } else if (this.selected_hazard === "FLOOD") {
        this.data_tree_risk = this.data_tree_flood;
      }
      setTimeout(() => {
        this.tree_reload = false;
      }, 500);
      setTimeout(() => {
        this.tree_reload = true;
      }, 600);
    },
    ChangeMunicipalities() {
      // //console.log(this.selectedMunicipalities)
      this.selected_area_of_interest = null;
      this.AreaOfInterest = null;
    },
    async createTree() {
     
      var pois_layers_all = await axios.get("layers/pois/");
      //console.log(pois_layers_all)

      var pois_layers = pois_layers_all.data;
      for (var rr = 0; rr < pois_layers.length; rr++) {
        ////console.log(rr)
        for (var qq = 0; qq < this.data.length; qq++) {
          ////console.log(this.data[qq].text)
          var find_name_og_layer = false;
          //for loop gia na dw an uparxei sto dentro h antistixi katigoria
          if (this.data[qq].text === pois_layers[rr].type_l02) {
            find_name_og_layer = true;
            break;
          }
        }

        if (!find_name_og_layer) {
          this.data.push({
            text: pois_layers[rr].type_l02,
            value: pois_layers[rr].type_l02.replaceAll(" ", "_"),
            //"value":pois_layers[rr].type_l02,
            children: [],
            opened: false,
          });
        }
        this.data[qq].children.push({
          text: pois_layers[rr].type_l03,
          // "icon": "fas fa-gas-pump",
          icon: "fas fa-layer-group",
          value: pois_layers[rr].type_l03.replaceAll(" ", "_"),
          //"value": pois_layers[rr].type_l03,
          dropDisabled: true,
          children: [],
          selected: false,
          disabled: false,
        });
      }
      this.data.push({
            text: 'Αdministrative Βoundaries',
            value: '',
            disabled: true,
            //"value":pois_layers[rr].type_l02,
            children: [
              {
                text: 'Municipality boundaries',
                value:'perifereia:geodata_municipalities_boundaries',
                type:'geoserver',
                name:'general'
              },
              {
                text: 'Regional units',
                value: 'perifereia:geodata_regional_units',
                type:'geoserver',
                name:'general'


              }            
            ],
            opened: false,
      })
      this.general_geoserver_bool={
        'perifereia:geodata_municipalities_boundaries':false,
        'perifereia:geodata_regional_units':false
      }
      ////console.log(typeof(this.data[2]))
      ////console.log(typeof(this.data[3]))
      ////console.log(this.data)
    },


    //otan ftiaxnw to pdf mporei o pinakas na einai polu megalos opote na kovete h photo sto telos ths selidas
    //me thn parakatw sunarthsh sunexizw se nea selida thn photo
    slit_img(doc, canvas, tab_name) {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      // var doc = new jsPDF('p', 'mm');
      var position = 10; // give some top padding to first page
      doc.text(tab_name, 1, 5);
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
    },
/*
    async generatePdf() {
      this.tst = false;
      const options = {
        type: "dataURL",
        useCORS: true,
        logging: false,
        foreignObjectRendering: true,

        //allowTaint:true,
      };
      const pdf = new jsPDF();
      //se auth thn for travaw screenshot ola ta talbe apo ta layers pou exoun epilexthei
      //an den kanw thn for tote bgainei mono to active tab . gia auton ton logo kanw active ola ta table wste h html2canvas na ta 'dei'
      // //console.log(this.$refs.table.length)
      for (var tt = 0; tt < this.$refs.table.length; tt++) {
        this.$refs.tabs[tt].localActive = true;

        const el_table = this.$refs.table[tt].$el;

        var output_talbe = await this.$html2canvas(el_table, {
          logging: false,
        });
        ////console.log(output_talbe)
        var tab_name = this.$refs.tabs[tt].title;
        if (!tab_name.includes("(0)")) {
          this.slit_img(pdf, output_talbe, tab_name);
          pdf.addPage();
        }

        ////console.log(el_table)
      }

      this.show_table = false;

      const el = this.$refs.map.$el;

      this.output = await this.$html2canvas(el, options);

      var h = this.$refs.div_map.clientHeight;

      pdf.addImage(this.output, "PNG", 5, 5, 200, h / 8);
      pdf.save("sample-file.pdf");

      this.tst = true;
    },
*/
    //katharizw to map apo ta sximata kai fernw pali ola ta epilegmen layers
    async reset_layers() {
      this.clear_layers();

      this.table_button_disabled = true;
      this.selected_area_of_interest = [];
      this.AreaOfInterest = null;
      this.selected_area_info = null;
      this.input_string = "all";
      this.radius = null;

      //this.filtering_polygon('all',null,true) //fere ola ta data apo ta epilegmena layers

      this.reset_layers_disabled = true;
      for (var aa = 0; aa < this.booleanOflayerArray.length; aa++) {
        this.booleanOflayerArray[aa] = false;
      }

      (this.data = [
        {
          text: "Utilities",
          value: "Utilities",

          children: [
            {
              text: "admie Lines",
              icon: "fas fa-layer-group",
              value: "admie_line",
              dropDisabled: true,
              selected: false,
              disabled: false,
            },
            {
              text: "admie Towers",
              icon: "fas fa-layer-group",
              value: "admie_towers",
              dropDisabled: true,
              selected: false,
              disabled: false,
            },
          ],
        },
      ]),
        this.createTree();
    },

    //prostese sto shmeio pou exei orisei o user ena marker
    search_coordinates() {
      ////console.log(this.coordinates_map)
      var tr = this.coordinates_map.split(",");
      tr[0] = parseFloat(tr[0]);
      tr[1] = parseFloat(tr[1]);

      ////console.log(tr[0],tr[1])
      this.center = [tr[0], tr[1]];
      this.coordinates_map_center = [tr[0], tr[1]];

      //this.center=[]
    },

    myRowClickHandler(record) {
      ////console.log(index)
      var tbody;
      var all_rows;
      //kanw ola to background twn rows aspro
      for (var i = 0; i < this.$refs.tabs.length; i++) {
        tbody = this.$refs.table[i].$el.querySelector("tbody");
        all_rows = tbody.querySelectorAll("tr");
        all_rows.forEach((index) => {
          index.style.backgroundColor = "white";
        });
      }

      var bound = record.center;
      ////console.log(bound)
      this.$refs.map.mapObject.flyTo([bound[1], bound[0]], 14, 50);

      setTimeout(() => {
        this.$refs.features.mapObject.openTooltip([bound[1], bound[0]]);
        if (record.name !== undefined) {
          this.popup_text = record.name;
        } else {
          this.popup_text = record.type;
        }
      }, 500);
    },
    /*itemClick_autopsies(node) {
      this.url_test = "http://185.4.134.30:8080/geoserver/perifereia/wms";

      if (node.model.selected) {
        if (!this.layers_autopsies.includes(node.model.value)) {
          ////console.log(value.split("-")[0])
          this.layers_autopsies.push(node.model.value);
        }
      }

      ////console.log(this.layers_flood.indexOf(value))
      ////console.log(this.layers_flood)
      ////console.log(value)
      this.boolean_layers_autopsies[
        this.layers_autopsies.indexOf(node.model.value)
      ] = node.model.selected;

      var num = 0.00000000000000000000001;
      //otan allazw ta data sto map den emfanizonte kateuthian. gia auto ton logo allazw elaxista ta bounds
      this.$refs.map.mapObject.fitBounds([
        [this.bounds._LAVREOTIKI - NORTHEast.lat + num, this.bounds._LAVREOTIKI - NORTHEast.lng + num],
        [this.bounds._LAVREOTIKI - SOUTHWest.lat - num, this.bounds._LAVREOTIKI - SOUTHWest.lng - num],
      ]);
      this.reset_layers_disabled = false;
    },*/
    async itemClick_flood(node) {
      console.log(node.model)
      
      
      if(node.model.selected){
        node.model.opened=true
      }
      
      this.reset_layers_disabled = false;

      this.url_test = "https://geoserver.idcom.gr/geoserver/perifereia/wms";
      ////console.log(node.model)

      if (node.model.children.length > 0) {
        for (var i = 0; i < node.model.children.length; i++) {
          if (node.model.children[i].children.length > 0) {
            // //console.log(node.model.children[i].children.length)
            for (var pp = 0;pp < node.model.children[i].children.length;pp++) {
              
              for(var xx=0;xx<node.model.children[i].children[pp].value.length;xx++){

              if(node.model.children[i].children[pp].value[xx]){
                
                this.create_Layer_Flood_Function(
                node.model.children[i].children[pp].value[xx].layer,
                node.model.children[i].children[pp].value[xx].location_name,
                node.model.selected,
                node.model.children[i].children[pp].name,
                node.model.children[i].children[pp].text
                );
              }
              
              }
              ////console.log(this.layers_flood)
            }
          } else {
            for(var vv=0;vv<node.model.children[i].value.length;vv++){
              //console.log(node.model.children[i].value[vv])
           if(node.model.children[i].value[vv]){
            this.create_Layer_Flood_Function(
              node.model.children[i].value[vv].layer,
              node.model.children[i].value[vv].location_name,
              node.model.selected,
              node.model.children[i].name,
              node.model.children[i].text
            );
            }
            }
          }
        }
      } else {

        for(var qq=0;qq<node.model.value.length;qq++){
         // //console.log(this.selected_area_of_interest)
         if(node.model.value[qq]){
          this.create_Layer_Flood_Function(
            node.model.value[qq].layer,
            node.model.value[qq].location_name,
            node.model.selected,
            node.model.name,
            node.model.text
          );
          }
          
        }
      }

      //var num=0.00000000000000000000001
      //otan allazw ta data sto map den emfanizonte kateuthian. gia auto ton logo allazw elaxista ta bounds
      //this.$refs.map.mapObject.fitBounds([[this.bounds._LAVREOTIKI - NORTHEast.lat+num, this.bounds._LAVREOTIKI - NORTHEast.lng+num ],[this.bounds._LAVREOTIKI - SOUTHWest.lat-num,this.bounds._LAVREOTIKI - SOUTHWest.lng-num]])
      ////console.log(node.model.name)
      if (node.model.name === "autopsies:SCHINOS" && node.model.selected) {
        this.$refs.map.mapObject.flyTo(
          [38.057727196637636, 23.186661477401195],
          12,
          1
        );
      } /*else if(node.model.name==='autopsies:GIORGIS' && node.model.selected){
          this.$refs.map.mapObject.flyTo([38.1260656204533,23.475379943847656],12,1)
       }*/ else if (
        node.model.name === "autopsies:SARANTAPOTAMOS" ||
        (node.model.name === "autopsies:GIORGIS" && node.model.selected)
      ) {
        this.$refs.map.mapObject.flyTo(
          [38.12588230270954, 23.4387933510128],
          12,
          1
        );
      }else if ((node.model.name === "autopsies:VARIMPOMPI" ||node.model.name === "autopsies:MARATHONAS"  ||node.model.name === "autopsies:KIFISOS" ) && node.model.selected ) {
         this.$refs.map.mapObject.flyTo(
          [ 38.06572598359864,23.92085571289063],
          11,
          1
        );
      }
       else {
        var num = 0.00000000000000000000001;
        //otan allazw ta data sto map den emfanizonte kateuthian. gia auto ton logo allazw elaxista ta bounds
        //console.log(this.bounds)
        this.$refs.map.mapObject.fitBounds([
          [this.bounds._northEast.lat + num, this.bounds._northEast.lng + num],
          [this.bounds._southWest.lat - num, this.bounds._southWest.lng - num],
        ]);
      }
      /*
      if(node.model.name.includes('Ignition-Point')){
        var point=node.model.name.split('-Point-')
        if(node.model.selected){
          this.ignition_point.push(parseInt(point[1]))
        }else{
          var tt=this.ignition_point.indexOf(parseInt(point[1]))
          this.ignition_point.splice(tt,1)
        }
        ////console.log(this.ignition_point)
      }*/
      ////console.log(node.model.name)
      if (node.model.name.includes("ignition-Point")) {
        var point = node.model.name.split("-Point-");
        if (node.model.selected) {
          ////console.log(node.model)
          if( !this.selected_area_of_interest.includes(node.model.value[0].location_name)){
              this.selected_area_of_interest.push(node.model.value[0].location_name)
          }
          ////console.log(this.selected_area_of_interest)
          // if(this.ignition_point[parseInt(point[1])-1]<5){
          this.ignition_point[parseInt(point[1])]++;
          // }

        } else {
          //var tt=this.ignition_point.indexOf(parseInt(point[1]))
          //this.ignition_point.splice(tt,1)
          if (this.ignition_point[parseInt(point[1])] > 0) {
            this.ignition_point[parseInt(point[1])]--;
          }
        }
        ////console.log(this.ignition_point);
      }
      if (node.model.name.includes("add-location")) {
        if (node.model.selected) {
          ////console.log(node.model)
          if( !this.selected_area_of_interest.includes(node.model.value[0].location_name)){
              this.selected_area_of_interest.push(node.model.value[0].location_name)
          }

        }
      }

      if(node.model.name.includes('Ignition-All-Points')){
        this.show_ignition_points=node.model.selected
      }
      
    },
    async create_Layer_Flood_Function(value,location, selected, name,text) {
      // var value=field_value
      //console.log(value)
      //console.log(name)
      if (name === "flood") {
        if (selected) {
          if (value.includes("perifereia:")) {
            if(!this.layers_flood[location]){
              this.layers_flood[location]=[]
            }
            if (!this.layers_flood[location].includes(value)) {
              this.layers_flood[location].push(value);
             
              //console.log('flood',this.layers_flood)
            }
          } else {
            
            ////console.log(this.flood_critical_points_names)
            if (value.includes("hydrographic")) {
             
              if(!this.flood_hydropraphic_names[location]){
                this.flood_hydropraphic_names[location]=[]
              }
              // //console.log('hydrographic',value)
              if(!this.num_of_hydropraphic.includes(value)){
                this.num_of_hydropraphic.push(value)
                var koiti = value.split("-!");
              var flood_hydropraphic = await axios.get("Flood-hydrographic/", {
                params: {
                  koiti: koiti[1],
                },
              });
              //console.log('flood_hydropraphic',flood_hydropraphic.data);
           
              this.flood_hydropraphic_data.push(flood_hydropraphic.data);
              
              ////console.log(this.flood_hydropraphic_data)
              ////console.log(this.num_of_hydropraphic)
              
              }
              if(!this.flood_hydropraphic_names[location].includes(value)){
                this.flood_hydropraphic_names[location].push(value);
              }
              
              //console.log(this.flood_hydropraphic_names)
              
              
            } else if(value.includes("critical points")) {
               ////console.log('critical points')
              // if(!this.flood_critical_points_names[location]){
              // this.flood_critical_points_names[location]=[]
             // }
              if (!this.flood_critical_points_names.includes(value)) {
                var priority = value.split("-!");//Infrastructure Inside River Bed-!1st Priority
//Infrastructure Inside River Bed
//1st Priority
                var flood_critical = await axios.get("Flood-Critical-Points/", {
                  params: {
                    critical_point_name: priority[0],
                    priority: priority[1],
                  },
                });
                //console.log(this.flood_critical_points);
                //if(!this.flood_critical_points[location]){
                //  this.flood_critical_points[location]=[]
               // }

                //this.flood_critical_points[location].push(flood_critical.data);
               if(!this.flood_critical_points[priority[1]]){
                 this.flood_critical_points[priority[1]]={}
               }
               this.flood_critical_points[priority[1]][priority[0]]=flood_critical.data;
                //console.log(this.flood_critical_points)
                this.flood_critical_points_names.push(value);
                //console.log(this.flood_critical_points_names)
              }
            }
            else if(value.includes("shelters")) {
              //console.log('shelters')
              if(!this.flood_shelters_names[location]){
               this.flood_shelters_names[location]=[]
              }
              if (!this.flood_shelters_names[location].includes(value)) {
      
                var flood_shelters_all = await axios.get("Flood-shelters/", {
                });
                
                this.flood_shelters.push(flood_shelters_all.data);
                //console.log(this.flood_shelters)
                this.flood_shelters_names[location].push(value);
              }
            }
            else if(value.includes("kliseis-pyros")) {
              //console.log('kliseis-pyros')
              if(!this.flood_kliseis_pyros_names[location]){
               this.flood_kliseis_pyros_names[location]=[]
              }
              //console.log(this.flood_kliseis_pyros_names[location])

              if (!this.flood_kliseis_pyros_names[location].includes(value)) {

                var flood_kliseis_pyros_all = await axios.get("Flood-kliseis-pyros/", {});
                
                //this.flood_kliseis_pyros.push(flood_kliseis_pyros_all.data);
               
                //console.log(this.flood_kliseis_pyros)
                this.flood_kliseis_pyros={}
                this.flood_kliseis_pyros_names[location].push('kliseis-pyros-2006-2009');
                this.flood_kliseis_pyros_names[location].push('kliseis-pyros-2010-2013');
                this.flood_kliseis_pyros_names[location].push('kliseis-pyros-2014-2017');
                this.flood_kliseis_pyros_names[location].push('kliseis-pyros-2018-2021');
                this.flood_kliseis_pyros_names[location].push('kliseis-pyros-2022-2023');
                
                for(var zz in flood_kliseis_pyros_all.data ){
                    //console.log(zz)
                    if(!this.flood_kliseis_pyros[zz]){
                      this.flood_kliseis_pyros[zz]={}
                    }
                    for(var rr=0; rr<flood_kliseis_pyros_all.data[zz].length; rr++){
                      
                      if(flood_kliseis_pyros_all.data[zz][rr].dateright>=2006 && flood_kliseis_pyros_all.data[zz][rr].dateright<=2009){
                        if(!this.flood_kliseis_pyros[zz]['kliseis-pyros-2006-2009']){
                          this.flood_kliseis_pyros[zz]['kliseis-pyros-2006-2009']=[]
                        }
                        this.flood_kliseis_pyros[zz]['kliseis-pyros-2006-2009'].push(flood_kliseis_pyros_all.data[zz][rr])
                      }
                      if(flood_kliseis_pyros_all.data[zz][rr].dateright>=2010 && flood_kliseis_pyros_all.data[zz][rr].dateright<=2013){
                        if(!this.flood_kliseis_pyros[zz]['kliseis-pyros-2010-2013']){
                          this.flood_kliseis_pyros[zz]['kliseis-pyros-2010-2013']=[]
                        }
                        this.flood_kliseis_pyros[zz]['kliseis-pyros-2010-2013'].push(flood_kliseis_pyros_all.data[zz][rr])
                      }
                     
                    }
                    //markers.push(L.marker([lat, lng], {icon:icon}).bindPopup(`Hello ${i}`))
                }
                //console.log(this.flood_kliseis_pyros)

              }
            }
          }
          ////console.log(value.split("-")[0])

        }

        if(this.layers_flood[location]){
          if(!this.boolean_layers_flood[location]){
            this.boolean_layers_flood[location]=[]
          }
          this.boolean_layers_flood[location][this.layers_flood[location].indexOf(value)] = selected;
        }
        
        ////console.log(this.layers_flood)
        if(this.flood_shelters_names[location]){
          this.boolean_flood_shelters[this.flood_shelters_names[location].indexOf(value)] = selected;
        }
        
        if(this.flood_kliseis_pyros_names[location]){
          if(!this.boolean_flood_kliseis_pyros[location]){
            this.boolean_flood_kliseis_pyros[location]=[]
          }
          this.boolean_flood_kliseis_pyros[location][this.flood_kliseis_pyros_names[location].indexOf(value)] = selected;
        }
        //console.log(this.boolean_flood_kliseis_pyros)
        //console.log(this.flood_kliseis_pyros_names)
       
        this.boolean_flood_critical_points[this.flood_critical_points_names.indexOf(value)] = selected;
      
       ////console.log(this.boolean_flood_critical_points)
       if(this.flood_hydropraphic_names[location]){
        this.boolean_flood_hydropraphic_data[this.flood_hydropraphic_names[location].indexOf(value)] = selected;
        //console.log(this.boolean_flood_hydropraphic_data)
       }
        this.setBoundMap()
      } else if (name === "seismic") {
        if (selected) {
          if (value === "Drill") {
            this.show_seismic_drill[location] = true;
          } else if (value === "Photos") {
            this.show_seismic_photos[location] = true;
          }else if(value === "refuge"){
            this.show_seismic_xoroi_katafigis[location] = true;
          }

          if(!this.layers_seismic[location]){
              this.layers_seismic[location]=[]
          }
          if (!this.layers_seismic[location].includes(value)) {
            ////console.log(value.split("-")[0])
            
            this.layers_seismic[location].push(value);
            console.log('seismic',this.layers_seismic)
            /*if(value.includes('elliniko')||value.includes('argyro')){
                ////console.log('here')
                 setTimeout(() => {
                    this.$refs.map.mapObject.flyTo([37.907783972444946, 23.74179840087891],16,30);
                  }, 200);
                
            }*/
           // if(legend_data){
            //  this.legend_object.push(legend_data)
              ////console.log('legend_object',this.legend_object)
           // }
            
          }
          ////console.log("seismic",this.layers_seismic)
        } else {
          if (value === "Drill") {
            this.show_seismic_drill[location] = false;
          } else if (value === "Photos") {
            this.show_seismic_photos[location] = false;
          }else if(value === "refuge"){
            this.show_seismic_xoroi_katafigis[location] = false;
          }
        }
       
        //----------Legeand ------------------------
       /* if(legend_data){
          
          for(var kk in this.legend_object){
            
            if(this.legend_object[kk].text===value ){
              
              this.legend_object[kk]=legend_data
              this.legend_object.splice(kk,1)
              
            }
          }
          if(selected){
            this.legend_object.push(legend_data)
          }
              
          ////console.log('legend_object',this.legend_object)
        }*/
        //--------------------------------------------
        if(this.layers_seismic[location]){
          if(!this.boolean_layers_seismic[location]){
            this.boolean_layers_seismic[location]=[]
          }
          console.log(value)
          this.boolean_layers_seismic[location][this.layers_seismic[location].indexOf(value)] =selected;
        }
       console.log(this.boolean_layers_seismic)
      } else if (name.includes("fire")) {
        if (selected) {
          var file_name
          if (value.includes("autopsies-points")) {
            this.show_fire_autopsies_points = true;
            file_name=value.split('-!')
            ////console.log(file_name[1])
            this.bool_autopsies_fire_array[file_name[1]]=true
          }
          if(!this.layers_fire[location]){
              this.layers_fire[location]=[]
          }
          if (!this.layers_fire[location].includes(value)) {
            if (value.includes("autopsies-points")) {
              file_name=value.split('-!')
              ////console.log(file_name[1])
              //await this.AutopsiesFirePointsFiles(file_name[1])
              var data_test=await axios.get('autopsies-fire-file/', { params: { name: file_name[1],text:text} })
              //console.log(data_test)
              
            
                this.array_photos_lat_lng.push(data_test.data)
              
              
              this.bool_autopsies_fire_array[file_name[1]]=true
              //console.log(this.array_photos_lat_lng)
              
              //this.AutopsiesFirePoints(this.autopsies_fire_array[file_name[1]],file_name[1]);
              
            }
            
            

            this.layers_fire[location].push(value);
            //console.log('fire',this.layers_fire)
            ////console.log(value.split("-")[0])
          }
        } else {
          if (value.includes("autopsies-points") ) {
            //this.show_fire_autopsies_points = false;
            file_name=value.split('-!')
            this.bool_autopsies_fire_array[file_name[1]]=false
          }
        }
        if(this.layers_fire[location]){
          if(!this.boolean_layers_fire[location]){
            this.boolean_layers_fire[location]=[]
          }
          this.boolean_layers_fire[location][this.layers_fire[location].indexOf(value)] = selected;
        }
        //console.log('boolean_layers_fire',this.boolean_layers_fire)
      }else if(name === 'general'){
        this.general_geoserver_bool[value]=selected
      } 
      else {
        var loc;
        if (selected) {
          if (!this.layers_autopsies.includes(value)) {
            ////console.log(value.split("-")[0])
            this.layers_autopsies.push(value);
          }
          if (value.includes("location:")) {
            loc = value.split(":");
            this.location_array.push(loc[1]);
          }
          ////console.log(this.location_array)
          //this.$refs.map.mapObject.flyTo([38.057727196637636,23.186661477401195],12,30)
        } else {
          if (value.includes("location:")) {
            loc = value.split(":");
            this.location_array = this.location_array.filter(
              (e) => e !== loc[1]
            );
          }
          ////console.log(this.location_array)
        }
        this.boolean_layers_autopsies[this.layers_autopsies.indexOf(value)] =
          selected;
      }

      ////console.log(this.layers_flood.indexOf(value))
      ////console.log(this.layers_autopsies)
      ////console.log(value)
    },
setBoundMap(){
   var num = 0.00000000000000000000001;
              //otan allazw ta data sto map den emfanizonte kateuthian. gia auto ton logo allazw elaxista ta bounds
              this.$refs.map.mapObject.fitBounds([
                [
                  this.bounds._northEast.lat + num,
                  this.bounds._northEast.lng + num,
                ],
                [
                  this.bounds._southWest.lat - num,
                  this.bounds._southWest.lng - num,
                ],
              ]);
},
customItemClickWithCtrl: function () {
                console.log('click + ctrl')
    },
    async itemClick(node) {
      this.table_button_disabled = false;
      this.reset_layers_disabled = false;
      this.tst = false;
      var ind;

      var data_all;
      if(node.model.type!=='geoserver'){

      if (node.model.children.length > 0) {
       
        for (var i = 0; i < node.model.children.length; i++) {
          this.nameOflayer = node.model.children[i].value;
          /*if(!this.layersData[this.nameOflayer]){
                this.nameOflayerArray.push(this.nameOflayer)
                // //console.log(this.input_string)
                data_all=await axios.get(this.nameOflayer+"/", { params: { input_area: this.input_string, radius:this.radius } })
                this.layersData[this.nameOflayer]=data_all.data
                
                this.create_table()
               }*/
          if (node.model.selected) {
            //if(!this.layersData[this.nameOflayer]){
            //  this.nameOflayerArray.push(this.nameOflayer)
            //}
            data_all = await axios.get("layers/" + this.nameOflayer + "/", {
              params: { input_area: this.input_string, radius: this.radius },
            });
            this.layersData[this.nameOflayer] = data_all.data;

            ////console.log(this.layersData)

            if (!this.nameOflayerArray.includes(this.nameOflayer)) {
              this.create_table();
              this.nameOflayerArray.push(this.nameOflayer);
            } else {
              // //console.log(this.nameOflayerArray.indexOf(this.nameOflayer))
              ind = this.nameOflayerArray.indexOf(this.nameOflayer);
              this.create_table(ind);
            }
          }

          //this.polygonsData[this.nameOflayer]=[]

          ////console.log(this.layersData)
          this.booleanOflayerArray[
            this.nameOflayerArray.indexOf(this.nameOflayer)
          ] = node.model.children[i].selected;
          /* 
              if(this.booleanOflayerArray[this.nameOflayerArray.indexOf(this.nameOflayer)] && !this.polyline[this.nameOflayer+'latlngs']){
                this.createArrayOfData(this.nameOflayer)
              }*/
        }
        this.nameOflayer = node.model.value;
      } else {
        
        this.nameOflayer = node.model.value;
        if (node.model.selected) {
          //if(!this.layersData[this.nameOflayer]){
          //  this.nameOflayerArray.push(this.nameOflayer)
          //}

          //pairnw ta data apo thn vash gia to kathe layer
          data_all = await axios.get("layers/" + this.nameOflayer + "/", {
            params: { input_area: this.input_string, radius: this.radius },
          });
          this.layersData[this.nameOflayer] = data_all.data;
          ////console.log( this.layersData)
          //an einai h prwth fora pou patithike to layer tote ftia3e to table
          if (!this.nameOflayerArray.includes(this.nameOflayer)) {
            this.create_table();
            this.nameOflayerArray.push(this.nameOflayer);
          }
          //alliws phgaine sto shmeio pou exei apothikeytei sto table ta data tou layer kai anabathmise
          else {
            // //console.log(this.nameOflayerArray.indexOf(this.nameOflayer))
            ind = this.nameOflayerArray.indexOf(this.nameOflayer);
            this.create_table(ind);
          }
        }

        //this.polygonsData[this.nameOflayer]=[]

        // //console.log(this.items)
        ////console.log(this.layersData)
        ////console.log(JSON.parse( this.layersData.railwaylines[0].center))

        this.booleanOflayerArray[
          this.nameOflayerArray.indexOf(node.model.value)
        ] = node.model.selected;
        

        

      }
      }else{
        
        
          //console.log('geoserver')
          // //console.log(this.selected_area_of_interest)
          if(node.model.value){
            this.create_Layer_Flood_Function(
              node.model.value,
              node.model.value,
              node.model.selected,
              node.model.name,
              node.model.text
            );
            }
          
          
      }

      this.tst = true;
    },
    create_table(index) {
      var item = [];
      var field = [];

      //olizw ta field tou table dunamika sumfwna me ta dedomena pou exronte apo thn vash
      for (var tt in this.layersData[this.nameOflayer][0]) {
        ////console.log(tt)
        ////console.log(this.layersData[this.nameOflayer][0])
        //an to layer pou epilexthike htan polyline tote mhn to prostheseis sto table.
        //prosthetw sta items to false kai panw sto div pou vazw ta tabs elegxw ama einai false i exei data, wste na prosperasei to layer auto kai na mhn to valei sta tab
        if (this.layersData[this.nameOflayer][0][tt]) {
          if (
            this.layersData[this.nameOflayer][0][tt].includes("MultiLineString")
          ) {
            if (index === undefined) {
              this.items.push(false);
            } else {
              this.items[index] = false;
            }

            this.first_time = false;
            return;
          }
        }

        if (!tt.includes("_geom")) {
          field.push({ key: tt, sortable: true });
        } else {
          if (!this.layersData[this.nameOflayer][0][tt].includes("Point")) {
            field.push({ key: "polygon", variant: "primary" });
          }
        }
      }

      //gemizw ton pinaka
      for (var dd = 0; dd < this.layersData[this.nameOflayer].length; dd++) {
        var row_of_table = {};
        //row_of_table['id']=dd
        // //console.log(this.layersData[this.nameOflayer][0])
        for (var rr in this.layersData[this.nameOflayer][0]) {
          if (
            !rr.includes("_geom") &&
            this.layersData[this.nameOflayer][dd][rr] != null
          ) {
            ////console.log(this.layersData[this.nameOflayer][dd])
            if (this.layersData[this.nameOflayer][dd][rr].includes("Point")) {
              var coordinates = JSON.parse(
                this.layersData[this.nameOflayer][dd][rr]
              );

              row_of_table[rr] = coordinates.coordinates;
            } else {
              //row_of_table[rr]='This is <i>raw <strong>HTML</strong></i> <span style="color:red">content</span>'
              row_of_table[rr] = this.layersData[this.nameOflayer][dd][rr];
            }
          }
          //sta layers pou exoun geom polygon tote vazw to switch button gia na mporw na ta elegxw
          if (
            rr.includes("_geom") &&
            !this.layersData[this.nameOflayer][dd][rr].includes("Point") &&
            !this.layersData[this.nameOflayer][dd][rr].includes(
              "MultiLineString"
            )
          ) {
            ////console.log(this.layersData[this.nameOflayer][dd][rr])
            row_of_table["polygon"] =
              "<div class='form-check form-switch'><input name='" +
              this.nameOflayer +
              "' class='checkbox form-check-input' type='checkbox' id='" +
              this.layersData[this.nameOflayer][dd][rr] +
              "'></div>";
          }
        }

        //item.push({name:this.layersData[this.nameOflayer][dd].name,osm_type:this.layersData[this.nameOflayer][dd].osm_type})
        ////console.log(row_of_table)
        item.push(row_of_table);
        // //console.log(item)
      }
      // //console.log(item)
      ////console.log(this.layersData[this.nameOflayer][0].findIndex())
      if (this.first_time) {
        this.items = [];
        this.fields = [];
      }
      ////console.log(index)
      //an einai h prwth fora pou mpainoun data sto table gia to sugkekrimeno layer
      if (index === undefined) {
        this.items.push(item);
        this.fields.push(field);
      }
      // an exei data tote anabathise auta me ta kainourgia
      else {
        this.items[index] = item;
        this.fields[index] = field;
      }

      ////console.log(this.items)
      this.first_time = false;
      //this.show_table=true
    },

    zoomUpdated(zoom) {
      this.zoom = zoom;
      ////console.log(this.zoom)
    },
    centerUpdated(center) {
      this.center = center;
     // //console.log(center)
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
      ////console.log(this.bounds)
    },
    close() {
      //this.col_map=12
      //this.height_map='100vh'
      this.show_table = false;
    },
    show_all_polygons() {
      //katharizw to map an eixan epixthei polygon apo to switch button
      //this.polygonsData[this.nameOflayer]=[]
    },

    //otan kanw draw tote stelnw pali request sto db wste na mou ferei mono ta epithimita data
    async filtering_polygon(input_string, radius, reset) {
      this.tst = false;
      var active_layers = [];
      /*var num_of_active_layers=0;
     //metrw ta active layers
     for(var tt=0; tt<this.nameOflayerArray.length; tt++){
       if(this.booleanOflayerArray[tt]){
          num_of_active_layers++
       }
     }*/
      //if(num_of_active_layers<5){
      // if(true){
      var fs = 0;
      for (var i = 0; i < this.nameOflayerArray.length; i++) {
        //sta layers pou einai epilegmena filtare ta kai emfanise ta kainoyrgia data
        if (this.booleanOflayerArray[i] || reset) {
          //sto booleanOflayerArray exw poia layers einai active
          active_layers.push(this.nameOflayerArray[i]);
          // //console.log(active_layers)
          var data_all = await axios.get(
            "layers/" + this.nameOflayerArray[i] + "/",
            { params: { input_area: input_string, radius: radius } }
          );

          this.layersData[this.nameOflayerArray[i]] = data_all.data;
        }
        this.nameOflayer = this.nameOflayerArray[i];
        if (fs === 0) {
          this.first_time = true;
        }
        this.create_table();
        fs++;
      }
      //var num=0.00000000000000000000000001
      //otan allazw ta data sto map den emfanizonte kateuthian. gia auto ton logo allazw elaxista ta bounds
      //this.$refs.map.mapObject.fitBounds([[this.bounds._LAVREOTIKI - NORTHEast.lat+num, this.bounds._LAVREOTIKI - NORTHEast.lng+num ],[this.bounds._LAVREOTIKI - SOUTHWest.lat-num,this.bounds._LAVREOTIKI - SOUTHWest.lng-num]])
      // }
      // else{
      // alert('You must have maximum 4 active layers ')
      // }
      this.tst = true;
    },
  },
  mounted() {
    
       /*
       for(var tt in this.autopsies_fire_array){
          ////console.log(tt)
          
          this.AutopsiesFirePoints(this.autopsies_fire_array[tt],tt)
          
        
        }*/
        var v=this
   this.$nextTick(() => {
     v.getTreeObjects()
		});
  },
};
</script>

<style >
@import "../../src/assets/color_icon.css";
.loader {
  position: relative;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2001;
  background: url("../../public/img/load1.gif") 50% 50% no-repeat
    rgba(255, 193, 249, 0.5);
}
.btn-eye {
  padding: 0.1rem 0.5rem !important;
  font-size: 0rem !important;
}

.btn-link {
  color: rgb(0, 0, 0) !important;
}
.fa-plus-circle {
  color: red;
}
.fa-h-square {
  color: #0a4502;
}
.fa-gas-pump {
  color: #666305;
}
.fa-campground {
  color: #6b4605;
}

.fa-tint {
  color: #32a3ce;
}
.tree-default .tree-icon {
  width: 20px !important;
}
.leaflet-draw-draw-polyline {
  display: none !important;
}
.leaflet-draw-draw-marker {
  display: none !important;
}
.row > * {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.card-header {
  background-color: #003b5d;
}
.tree-anchor {
  font-size: 13px !important;
}

.nav-link {
  color: #ffffff !important;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #000000 !important;
}
/*
.table-striped > tbody > tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: #ffffff;
}
.table{
  color:#ffffff;
}*/
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  background: rgba(76, 175, 80, 0);
  text-shadow: 0 1px 0 #fff;
  color: rgb(0, 0, 0) !important;
  border-radius: 50%;
}
.tree-default .tree-wholerow-clicked {
  background: #a1a1a1 !important;
}
.tree-default .tree-wholerow-hovered {
  background: #8d8d8d !important;
}

.leaflet-control {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.bottomleft-control {
  box-shadow: 0 0rem 0rem rgb(0 0 0 / 15%) !important;
}
.leaflet-bottom.leaflet-left {
  background: #6c757d;
  border-radius: 0px 20px 0px 0px;
  box-shadow: 0 0rem 3rem rgb(0 0 0 / 45%) !important;
}
.right-sidebar {
  background: #f3f3f300;
}
.leaflet-draw-toolbar.leaflet-control-toolbar {
  margin-top: 0px;
  margin-bottom: 0px;
}

.logo-perifereia {
  width: 20vh;
}
.row {
  --bs-gutter-x: 0rem !important;
}

.container-fluid {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.collapse-mobile {
  display: none;
}
.control-desk {
  display: block;
}
@media only screen and (min-width: 990px) {
  /* Styles */
  .screen-size {
    height: 95vh;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 990px) {
  .screen-size {
    height: 60vh;
  }
  .logo-perifereia {
    width: 10vh;
  }
  .collapse-mobile {
    display: block;
  }
  .control-desk {
    display: none;
  }
}

.collapse_card {
  padding: 0rem 0rem !important;
}
.btn-secondary {
  color: #fff;
  background-color: #003b5d;
  border-color: #dddddd;
}
.btn_collapse {
  padding: 0.475rem 1rem !important;
}
.card-layers {
  background: #003b5d;
}
.custom-select {
  background-color: #c7c7c7;
}
.card-body {
  padding: 0rem 0rem !important;
}
.table-cont {
  z-index: 3001;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
  min-height: 50vh;
}

.draggable-div {
  box-shadow: 0 0rem 3rem rgb(0 0 0 / 0%) !important;
}

.revue-draggable {
  cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>🤏</text></svg>")
      16 0,
    auto;
}
.collapse-button {
  padding: 0.195rem 1rem !important;
}

.style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.style-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(199 199 199);
}
.tree-default .tree-disabled > .tree-icon {
  /* opacity: .8; */
  filter: 0 !important;
  filter: rgba(128, 128, 128, 0) !important;
  -webkit-filter: grayscale(0%) !important;
}
.collapse-button {
    padding: 0.195rem 0.5rem !important;
}
.tree-default .tree-disabled {
    color: rgb(0, 0, 0)!important;
}
.tree-default {
    color: rgb(0, 0, 0)!important;
}



</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

